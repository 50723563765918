import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addAnimationStyle,
  addAnimationType,
  addAnimationDelay,
  addAttentionSeeker,
  addAttentionCadence,
  addCadenceInterval,
  addAnimationEffect,
} from '../../../redux/customizerSidebarSlice';
import CustomSelect from './CustomSelect';
import { InputNumber } from 'antd';
import {
  ANIMATION_STYLE_OPTIONS,
  ANIMATION_DELAY_OPTIONS,
  ANIMATION_EFFECT_OPTIONS,
  ATTENTION_SEEKER_OPTIONS,
  ATTENTION_CADENCE_OPTIONS,
  ANIMATION_OPTIONS_ARRAY,
  ANIMATION_FADE_OPTIONS,
  ANIMATION_BOUNCE_OPTIONS,
  ANIMATION_ZOOM_OPTIONS,
  ANIMATION_SLIDING_OPTIONS,
} from '../../../layout/constants';

const AnimationComponent = () => {
  const dispatch = useDispatch();
  const {
    animationStyle,
    animationType,
    animationDelay,
    attentionSeeker,
    attentionCadence,
    cadenceInterval,
    animationEffect,
  } = useSelector((state) => state.customizer.customizerData);

  let mappedStyleObject,
    mappedDelayObject,
    mappedEffectObject,
    mappedAttentionSeekerObject,
    mappedAttentionCadenceObject,
    mappedAnimationType;

  if (animationStyle.length === 0) {
    mappedStyleObject = [{ label: 'BOUNCE', value: 'BOUNCE' }];
  } else {
    mappedStyleObject = ANIMATION_STYLE_OPTIONS.filter(
      (f) => f.value === animationStyle
    );
  }

  if (animationDelay.length === 0) {
    mappedDelayObject = [{ label: 'NO DELAY', value: 'NO_DELAY' }];
  } else {
    mappedDelayObject = ANIMATION_DELAY_OPTIONS.filter(
      (f) => f.value === animationDelay
    );
  }
  if (animationEffect.length === 0) {
    mappedEffectObject = [{ label: 'NONE', value: 'NONE' }];
  } else {
    mappedEffectObject = ANIMATION_EFFECT_OPTIONS.filter(
      (f) => f.value === animationEffect
    );
  }

  if (attentionSeeker.length === 0) {
    mappedAttentionSeekerObject = [{ label: 'BOUNCE', value: 'BOUNCE' }];
  } else {
    mappedAttentionSeekerObject = ATTENTION_SEEKER_OPTIONS.filter(
      (f) => f.value === attentionSeeker
    );
  }

  if (attentionCadence.length === 0) {
    mappedAttentionCadenceObject = [{ label: 'NONE', value: 'NONE' }];
  } else {
    mappedAttentionCadenceObject = ATTENTION_CADENCE_OPTIONS.filter(
      (f) => f.value === attentionCadence
    );
  }
  if (animationStyle.length === 0) {
    mappedAnimationType = [{ key: 'BOUNCE', value: ANIMATION_BOUNCE_OPTIONS }];
  } else {
    mappedAnimationType = ANIMATION_OPTIONS_ARRAY.find(
      (style) => style.key === animationStyle
    );
  }

  mappedAnimationType = mappedAnimationType
    ? mappedAnimationType.value.find((type) => type.value === animationType)
    : { label: 'NONE', value: 'NONE' };

  return (
    <>
      <CustomSelect
        label="Style"
        activeMenuItem={mappedStyleObject[0]}
        menuItems={ANIMATION_STYLE_OPTIONS}
        actionFunc={addAnimationStyle}
      />

      {animationStyle === 'BOUNCE' && (
        <CustomSelect
          label="Bounce options"
          isDisabled={
            mappedStyleObject &&
            mappedStyleObject.length > 0 &&
            mappedStyleObject[0].value === 'NONE'
          }
          activeMenuItem={mappedAnimationType}
          menuItems={ANIMATION_BOUNCE_OPTIONS}
          actionFunc={addAnimationType}
        />
      )}

      {animationStyle === 'FADE' && (
        <CustomSelect
          label="Fade options"
          isDisabled={
            mappedStyleObject &&
            mappedStyleObject.length > 0 &&
            mappedStyleObject[0].value === 'NONE'
          }
          activeMenuItem={mappedAnimationType}
          menuItems={ANIMATION_FADE_OPTIONS}
          actionFunc={addAnimationType}
        />
      )}

      {animationStyle === 'ZOOM' && (
        <CustomSelect
          label="Zoom options"
          isDisabled={
            mappedStyleObject &&
            mappedStyleObject.length > 0 &&
            mappedStyleObject[0].value === 'NONE'
          }
          activeMenuItem={mappedAnimationType}
          menuItems={ANIMATION_ZOOM_OPTIONS}
          actionFunc={addAnimationType}
        />
      )}

      {animationStyle === 'SLIDE_DOWN' && (
        <CustomSelect
          label="Slide options"
          isDisabled={
            mappedStyleObject &&
            mappedStyleObject.length > 0 &&
            mappedStyleObject[0].value === 'NONE'
          }
          activeMenuItem={mappedAnimationType}
          menuItems={ANIMATION_SLIDING_OPTIONS}
          actionFunc={addAnimationType}
        />
      )}

      <CustomSelect
        label="Delay"
        isDisabled={
          mappedStyleObject &&
          mappedStyleObject.length > 0 &&
          mappedStyleObject[0].value === 'NONE' &&
          mappedAttentionSeekerObject[0].value === 'NONE'
        }
        activeMenuItem={mappedDelayObject[0]}
        menuItems={ANIMATION_DELAY_OPTIONS}
        actionFunc={addAnimationDelay}
      />
      <CustomSelect
        label="Attention Seeker"
        activeMenuItem={mappedAttentionSeekerObject[0]}
        menuItems={ATTENTION_SEEKER_OPTIONS}
        actionFunc={addAttentionSeeker}
      />
      {attentionSeeker !== 'NONE' && (
        <CustomSelect
          label="Attention Cadence"
          activeMenuItem={mappedAttentionCadenceObject[0]}
          menuItems={ATTENTION_CADENCE_OPTIONS}
          actionFunc={addAttentionCadence}
        />
      )}

      {attentionCadence !== 'NONE' && (
        <div className="f-item">
          <div className="tit">Cadence Interval</div>
          <InputNumber
            min={1}
            defaultValue={cadenceInterval}
            onChange={(val) => {
              if (val >= 0) {
                dispatch(addCadenceInterval(val));
              }
            }}
            style={{
              width: '100%',
              height: '57px',
            }}
          />
        </div>
      )}

      <CustomSelect
        label="Effects"
        activeMenuItem={mappedEffectObject[0]}
        menuItems={ANIMATION_EFFECT_OPTIONS}
        actionFunc={addAnimationEffect}
      />
    </>
  );
};
export default AnimationComponent;
