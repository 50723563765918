import React, { useState } from 'react';
import classNames from 'classnames';

import Button from '../../../form/components/Button';
import campaigns from '../../../assets/imgs/popups/campaigns.png';
import Input from '../../../form/components/Input';
import { useCreateCampaign, useCustomizerMutationClient } from '../useMutations';
import { message } from 'antd';

const CampaignPopup = ({ successCB }) => {
    let [name, setCampaign] = useState(undefined);
    let [isSubmitting, setIsSubmitting] = useState(false);
    let [isActive, setActive] = useState(false);


    const [updateCustomizerClient] = useCustomizerMutationClient();
    let [createCampaign] = useCreateCampaign();

    return (
        <div className="campaigns-popup">
            <div className="ls">
                <div className="ls-content">
                    <div className="ls-title">bannernote</div>
                    <div className="ls-inner-content">
                        <img src={campaigns} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className="rs">
                <div className="rs-title">Create a new campaign</div>
                <div className="rs-desc">
                    Start to customize your banner in the customization
                    <br /> tool and complete the setup afterwards.
                </div>

                <div className="integration-input">
                    <div className="title">Name</div>
                    <Input
                        placeholder="Christmas Campaign"
                        className="ts-input"
                        style={{
                            fontSize: '26px',
                        }}
                        onChange={(e) => {
                            if (!e.target.value) {
                                setActive(false);
                            } else {
                                setActive(true);
                            }

                            setCampaign(e.target.value);
                        }}
                        value={name}
                    />
                </div>
                <Button
                    children="CREATE CAMPAIGN"
                    className={classNames('wf-btn-primary', { active: isActive })}
                    disabled={isSubmitting || !isActive}
                    onClick={async () => {
                        try {
                            setIsSubmitting(true);
                            const campaign = await createCampaign({
                                variables: {
                                    name: name,
                                },
                            });

                            if (
                                campaign.data &&
                                campaign.data.createCampaign &&
                                campaign.data.createCampaign.id
                            ) {
                                message.success('Campaign created successfully!');

                                updateCustomizerClient({
                                    variables: {
                                        isOpen: true,
                                        activeCampaign: campaign.data.createCampaign.id,
                                        ...campaign.data.createCampaign.customizer,
                                    },
                                });

                                successCB();
                            } else {
                                message.error('An error occured, please try again later!');
                            }
                        } catch (e) {
                            message.error('Unable to add new campaign!');
                        }

                        setIsSubmitting(false);
                    }}
                />
            </div>
        </div>
    );
};

export default CampaignPopup;
