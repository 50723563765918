import React, { useState } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Grid } from 'svg-loaders-react';
import classNames from 'classnames';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Button from '../../../form/components/Button';
import integrationLogo from '../../../assets/imgs/popups/integration.png';
import jsIcon from '../../../assets/imgs/popups/javascript-icon.png';
import sqIcon from '../../../assets/imgs/popups/squarespace-icon.png';
import wfIcon from '../../../assets/imgs/popups/webflow-icon.png';
import shIcon from '../../../assets/imgs/popups/shopify-icon.png';
import wxIcon from '../../../assets/imgs/popups/wix-icon.png';
import wdIcon from '../../../assets/imgs/popups/wordpress-icon.png';

import { ReactComponent as RightArrow } from '../../../assets/right-arrow-angle.svg';

import { copyToClipboard } from '../../../utils/generalUtils';
import Input from '../../../form/components/Input';
import { message } from 'antd';
import { useAddSinglePageMutation } from '../../projects/useMutations';
import { useHistory } from 'react-router-dom';

const StartIntegrationPopup = ({ successCB, apiKey }) => {
    let [pageUrl, setPageUrl] = useState(undefined);
    let [isSubmitting, setIsSubmitting] = useState(false);
    let [isActive, setActive] = useState(false);
    const history = useHistory();

    const [addSinglePage] = useAddSinglePageMutation();
    const saveHandler = async (e) => {
        let currentPageUrl = pageUrl;
        if (currentPageUrl && /^(http|https):\/\/[^ "]+$/.test(currentPageUrl)) {
            // const currentUserPlan =
            //     userPlan && userPlan.getUserPlan && userPlan.getUserPlan.plan
            //         ? userPlan.getUserPlan.plan.id
            //         : 1;
            // if (currentUserPlan === 1 && pagesCount >= 5 && pagesCount < 10) {
            //     setShowPopup(false);
            //     await updateUpgradeData({
            //         variables: {
            //             shouldShowUpgradePopup: true,
            //             targetPlan: 2,
            //             tempPageUrl: pageUrl,
            //         },
            //     });
            // } else if (currentUserPlan === 2 && pagesCount >= 10 && pagesCount < 20) {
            //     setShowPopup(false);

            //     await updateUpgradeData({
            //         variables: {
            //             shouldShowUpgradePopup: true,
            //             targetPlan: 3,
            //             tempPageUrl: currentPageUrl,
            //         },
            //     });
            // } else if (currentUserPlan === 3 && pagesCount >= 20) {
            //     setShowPopup(false);

            //     await updateUpgradeData({
            //         variables: {
            //             shouldShowUpgradePopup: true,
            //             targetPlan: 4,
            //             tempPageUrl: currentPageUrl,
            //         },
            //     });
            // } else
            {
                setIsSubmitting(true);
                try {
                    const results = await addSinglePage({
                        variables: { pageUrl: currentPageUrl },
                    });

                    if (results && results.data && results.data.addSinglePage) {
                        message.success('Page successfully added');
                        // await updateUpgradeData({
                        //     variables: {
                        //         shouldShowUpgradePopup: false,
                        //         tempPageUrl: null,
                        //         shouldResetUpgradeData: null,
                        //     },
                        // });
                        successCB();
                        if (history.location.pathname.includes('customizer')) {
                            history.push('/');
                        }
                    } else {
                        message.warn(
                            'Unable to verify the page, Please make sure the snippet is installed on your page!'
                        );
                    }
                } catch (e) {
                    message.error('An error occured while trying to save the page');
                    console.log(e);
                }

                setIsSubmitting(false);
            }
        } else {
            message.error('Please enter a valid page url');
        }
    };

    return (
        <div className="start-integration-popup">
            <div className="ls">
                <div className="ls-content">
                    <div className="ls-title">bannernote</div>
                    <div className="ls-inner-content">
                        <img src={integrationLogo} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className="rs">
                <div className="rs-title">Integrate your Banner in 2 Steps</div>
                <div className="rs-desc">
                    1. Your Code Snippet
                    <div className="sub-desc">
                        Paste the code below before the &lt;/head&gt; tag on every page you want the
                        banner to appear.
                    </div>
                </div>

                <div className="setup-p-code">
                    <div className="setup-code">
                        <Button children="COPY" onClick={(e) => copyToClipboard('code-snippet')} />
                        <SyntaxHighlighter language="javascript" style={dark} id="code-snippet">
                            {`<script
    id="bannernotesdk"
    src="https://app.bannernote.com/sdk/sdk.js?apiKey=${apiKey}">
</script>`}
                        </SyntaxHighlighter>
                    </div>
                </div>

                <div className="integ-guide">
                    <div className="title">Integration Guides</div>
                    <div className="integrations">
                        <Button
                            children={
                                <>
                                    <img src={jsIcon} style={{ height: '100%' }} />
                                    <span>Manual Install</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.gitbook.io/bannernote/"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open('https://bannernote.gitbook.io/bannernote/', '_blank')
                            }
                        />

                        <Button
                            children={
                                <>
                                    <img src={wdIcon} style={{ height: '100%' }} />
                                    <span>Wordpress</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.gitbook.io/bannernote/"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open(
                                    'https://bannernote.gitbook.io/bannernote/',
                                    '_blank'
                                )
                            }
                        />

                        <Button
                            children={
                                <>
                                    <img src={wfIcon} style={{ height: '100%' }} />
                                    <span>Webflow</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.gitbook.io/bannernote/webflow-integration"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open('https://bannernote.gitbook.io/bannernote/webflow-integration', '_blank')
                            }
                        />

                        <Button
                            children={
                                <>
                                    <img src={sqIcon} style={{ height: '100%' }} />
                                    <span>Squarespace</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.com/integrations/"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open(
                                    'https://bannernote.com/integrations/',
                                    '_blank'
                                )
                            }
                        />

                        <Button
                            children={
                                <>
                                    <img src={shIcon} style={{ height: '100%' }} />
                                    <span>Shopify</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.com/integrations/"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open('https://bannernote.com/integrations/', '_blank')
                            }
                        />

                        <Button
                            children={
                                <>
                                    <img src={wxIcon} style={{ height: '100%' }} />
                                    <span>Wix</span>
                                    <RightArrow />
                                </>
                            }
                            title="https://bannernote.com/integrations/"
                            className={classNames('wf-btn-primary')}
                            onClick={(e) =>
                                window.open('https://bannernote.com/integrations/', '_blank')
                            }
                        />
                    </div>
                </div>
                <div className="integration-input">
                    <div className="title">2. Verify Implementation</div>
                    <div className="subtitle">
                        Enter the specific page URL, where you have integrated the snippet and
                        validate your setup.
                    </div>
                    <Input
                        placeholder="https://yourwebsite.com"
                        className="ts-input"
                        style={{
                            fontSize: '26px',
                            outline: '1px solid #0c2e4e',
                        }}
                        onChange={(e) => {
                            if (!e.target.value) {
                                setActive(false);
                            } else {
                                setActive(true);
                            }

                            setPageUrl(e.target.value);
                        }}
                    />
                </div>

                <Button
                    children={
                        isSubmitting ? (
                            <Grid style={{ width: '30px', height: '17px' }} />
                        ) : (
                            'VERIFY IMPLEMENTATION'
                        )
                    }
                    className={classNames('wf-btn-primary', { active: isActive })}
                    disabled={isSubmitting || !isActive}
                    onClick={saveHandler}
                />
            </div>
        </div>
    );
};

export default StartIntegrationPopup;
