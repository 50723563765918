import React from 'react';
import { Row, Icon, Layout, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Grid } from 'svg-loaders-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { GoogleLogin } from 'react-google-login';

import InputField from '../../../form/components/InputField';
import Button from '../../../form/components/Button';
import { useSignup } from '../useMutations';
import { auth } from '../../auth';
import { showAllGraphQLErrors } from '../../../helper/graphqlErrors';
import { ROUTE_PATHS } from '../../../routes';
import Checkbox from '../../../form/components/Checkbox';
import GoogleAuthHooks from '../../GoogleAuthHooks';

const initialValues = {
  email: '',
  password: '',
  fullName: '',
  role: 'ADMIN',
  termsAndConditions: false,
};

const signupSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('*Required'),
  password: Yup.string().required('*Required'),
  fullName: Yup.string().required('*Required'),
  termsAndConditions: Yup.boolean().oneOf([true]),
});

const Signup = ({ routerHistory, role, clientId }) => {
  const [signup] = useSignup();
  if (role) {
    initialValues.role = role;
  }

  return (
    <>
      <GoogleAuthHooks btnText="Sign Up with Google" />

      <Formik
        initialValues={initialValues}
        validationSchema={signupSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await signup({
              variables: {
                fullName: values.fullName,
                email: values.email,
                password: values.password,
              },
            });
            if (result) {
              auth.setAccessToken(result.data.signup.token);
              // routerHistory.push('/customizer', { showWelcomePopup: true });
              window.location.assign('/customizer');
            }
          } catch (error) {
            setSubmitting(false);
            showAllGraphQLErrors(error.graphQLErrors);
          }
        }}
      >
        {(props) => {
          const { values, isSubmitting, dirty, errors } = props;
          return (
            <Form>
              <Row className="auth-row">
                <InputField
                  name="email"
                  type="text"
                  label="Email"
                  placeholder=""
                />
              </Row>

              <Row className="auth-row">
                <InputField
                  name="fullName"
                  type="text"
                  label="Full Name"
                  placeholder=""
                />
              </Row>

              <Row className="auth-row">
                <InputField
                  name="password"
                  type="password"
                  label="Password"
                  placeholder=""
                />
              </Row>

              <Row className="auth-row">
                <Checkbox
                  name="termsAndConditions"
                  type="checkbox"
                  linkLabel={
                    <div className="form-label">
                      By signing up, I agree to the{' '}
                      <Link to="/terms">terms & conditions</Link> and{' '}
                      <Link to="/privacy">privacy</Link> note.
                    </div>
                  }
                />
              </Row>

              <Row>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={isSubmitting}
                  className={classNames('wf-btn-primary', {
                    active: dirty && Object.keys(errors).length === 0,
                  })}
                >
                  {isSubmitting ? (
                    <Grid style={{ width: '17px', height: '17px' }} />
                  ) : (
                    <>CREATE ACCOUNT</>
                  )}
                </Button>
              </Row>

              <p className="login-wrapper__footer">
                <span>Already have an account?</span> <a href="/login">Login</a>
              </p>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Signup;
