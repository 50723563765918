import gql from "graphql-tag";
import { apolloClient } from "../apolloClient";

export default (
  _,
  {
    isOpen,
    branding,
    style,
    bannerText,
    fontFamily,
    fontWeight,
    titleTextAlign,
    subtitleText,
    subtitleFontFamily,
    subtitleFontWeight,
    subtitleFontSize,
    subtitleTextAlign,
    btnText,
    link,
    openingStyle,
    buttonStyle,
    buttonColor,
    textColor,
    closeButton,
    buttonAlignment,
    buttonPadding,

    backgroundColor,
    fontColor,

    activeCampaign,
    size,
    borderRadius,
    position,
    customPositionDiv,
    animationStyle,
    animationDelay,
    animationEffect,
    animationType,
    attentionSeeker,
    attentionCadence,
    cadenceInterval,
    fontSize,
    modalStyle,
    modalAlignment,
    modalBorderRadius,
    modalShadow,
    modalImage,
    isLoading,
  },
  { cache }
) => {
  const query = gql`
    query getCustomizerData {
      customizer @client {
        isOpen
        branding

        style
        bannerText
        fontFamily
        fontWeight
        titleTextAlign
        subtitleText
        subtitleFontFamily
        subtitleFontWeight
        subtitleFontSize
        subtitleTextAlign

        btnText
        link
        openingStyle
        buttonStyle
        buttonColor
        textColor
        closeButton
        buttonAlignment
        buttonPadding

        backgroundColor
        fontColor

        activeCampaign
        size
        borderRadius
        position
        customPositionDiv
        animationStyle
        animationDelay
        animationEffect
        animationType
        attentionSeeker
        attentionCadence
        cadenceInterval
        fontSize
        modalStyle
        modalAlignment
        modalBorderRadius
        modalShadow
        modalImage
        isLoading
      }
    }
  `;
  const previousState = apolloClient.readQuery({ query });
  const data = {
    customizer: {
      ...previousState.customizer,
      isOpen,
      branding,

      // Style
      style,

      // Copy
      bannerText,
      fontFamily,
      fontWeight,
      titleTextAlign,
      subtitleText,
      subtitleFontFamily,
      subtitleFontWeight,
      subtitleFontSize,
      subtitleTextAlign,
      // Button
      btnText,
      link,
      openingStyle,
      buttonStyle,
      buttonColor,
      textColor,
      closeButton,
      buttonAlignment,
      buttonPadding,
      animationType,
      attentionSeeker,
      attentionCadence,
      cadenceInterval,

      // Color
      backgroundColor,
      fontColor,

      activeCampaign,
      size,
      borderRadius,

      position,
      customPositionDiv,
      animationStyle,
      animationDelay,
      animationEffect,
      fontSize,
      modalStyle,
      modalAlignment,
      modalBorderRadius,
      modalShadow,
      modalImage,
      isLoading,
    },
  };

  apolloClient.writeData({
    query,
    data,
  });

  return null;
};
