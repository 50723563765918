import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addBranding } from '../../../redux/customizerSidebarSlice';
// import {
//   useCustomizerMutationClient,
//   useUpdateCampaignCustomizer,
// } from '../../../bannernote/customizer/useMutations';
import { message, Radio } from 'antd';
// import classNames from 'classnames';
// import Button from '../../../form/components/Button';
// import Popup from '../../../components/Popup';
// import Upgrade, { Step3 } from '../../../upgrade/components/Upgrade';

const BrandingComponent = ({
  // bannerVisible,
  // setPrevPosition,
  // customizerData,
  // setBrandingChangesSaved,
  userPlan,
}) => {
  const dispatch = useDispatch();
  const { branding } = useSelector((state) => state.customizer.customizerData);

  // const [btnActive, setBtnActive] = useState(false);
  // let [successPopup, showSuccessPopup] = useState(false);
  // let [showUpgradePopup, setShowUpgradePopup] = useState(false);
  // let [targetPlan, setTargetPlan] = useState(2);

  // const [updateCustomizerClient] = useCustomizerMutationClient();
  // const [updateCustomizer] = useUpdateCampaignCustomizer();

  // let { branding, activeCampaign } =
  //   customizerData && customizerData.customizer
  //     ? customizerData.customizer
  //     : {
  //         branding: '',
  //       };

  // const subscriptionCycle =
  //   userPlan && userPlan.getUserPlan
  //     ? userPlan.getUserPlan.subscriptionCycle
  //     : '';

  // const saveHandler = async () => {
  //   try {
  //     const results = await updateCustomizer({
  //       variables: {
  //         branding,
  //         campaignId: activeCampaign,
  //       },
  //     });
  //     if (results.data && results.data.updateCampaignCustomizer) {
  //       setBrandingChangesSaved(true);
  //       setBtnActive(false);
  //       message.success('Successfully saved.');
  //     } else {
  //       message.warn('Unable to save the change, please try again later');
  //     }
  //   } catch (e) {
  //     message.error('An error occured while trying to save the changes!');
  //   }
  // };

  return (
    <>
      <div className="customizer-menu-group">
        <Radio.Group
          value={branding}
          onChange={(e) => {
            dispatch(addBranding(e.target.value));
          }}
        >
          <Radio.Button value="WITH_BRANDING">WITH BRANDING</Radio.Button>
          <Radio.Button value="WITHOUT_BRANDING">WITHOUT BRANDING</Radio.Button>
        </Radio.Group>

        <div
          className="f-item"
          style={{ display: 'block', width: 'fit-content', margin: '0 auto' }}
        >
          {/* {btnActive && (
            <Button
              children="Publish Changes"
              className="wf-btn-primary__sidebar-customizer"
              onClick={async (e) => {
                const currentUserPlan =
                  userPlan && userPlan.getUserPlan && userPlan.getUserPlan.plan
                    ? userPlan.getUserPlan.plan.id
                    : 1;

                if (currentUserPlan === 1 && branding === 'WITHOUT_BRANDING') {
                  setTargetPlan(2);
                  setShowUpgradePopup(true);
                } else {
                  await saveHandler();
                }
              }}
            />
          )} */}
        </div>
      </div>

      {/* {showUpgradePopup && (
        <Popup
          component={() => {
            return (
              <Upgrade
                successCB={async () => {
                  setShowUpgradePopup(false);
                  // setShowPopup(true);
                  showSuccessPopup(true);
                }}
                // preStep={userStatus !==   'BASIC' && userStatus !== 'PREMIUM' ? 3 : 1}
                subscriptionCycle={subscriptionCycle}
                targetPlan={
                  userPlan &&
                  userPlan.getUserPlan &&
                  userPlan.getUserPlan.plan &&
                  userPlan.getUserPlan.plan.id
                    ? userPlan.getUserPlan.plan.id < 4 &&
                      userPlan.getUserPlan.plan.id >= 1
                      ? userPlan.getUserPlan.plan.id + 1
                      : userPlan.getUserPlan.plan.id
                    : 2
                }
              />
            );
          }}
          closePopup={async (e) => {
            setShowUpgradePopup(false);
          }}
          style={{
            minHeight: '700px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )} */}

      {/* {successPopup && (
        <Popup
          component={() => {
            return (
              <Step3
                successCB={async () => {
                  showSuccessPopup(false);
                }}
              />
            );
          }}
          closePopup={async (e) => {
            showSuccessPopup(false);
            await saveHandler();
          }}
          style={{
            minHeight: '700px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )} */}
    </>
  );
};
export default BrandingComponent;
