import { useMutation } from '@apollo/react-hooks';
import {
    ADD_SINGLE_PAGE,
    DELETE_PROJECT,
    SET_PAGE_CAMPAIGN,
    SET_PAGE_SELECTED_CAMPAIGN,
    UPDATE_CAMPAIGN_ON_PAGE_STATUS,
} from './gql';

export const useAddSinglePageMutation = () =>
    useMutation(ADD_SINGLE_PAGE, {
        refetchQueries: ['userPages', 'getUserPlan'],
        fetchPolicy: 'no-cache',
    });

export const useSetPageCampaign = () =>
    useMutation(SET_PAGE_CAMPAIGN, {
        refetchQueries: ['userPages'],
    });

export const useUpdateCampaignOnPageStatus = () =>
    useMutation(UPDATE_CAMPAIGN_ON_PAGE_STATUS, {
        refetchQueries: ['userPages'],
    });

export const useDeleteProject = () =>
    useMutation(DELETE_PROJECT, {
        refetchQueries: ['userPages'],
    });

export const setPageSelectedCampaign = () =>
    useMutation(SET_PAGE_SELECTED_CAMPAIGN, {
        refetchQueries: ['userPages'],
    });
