import { message, Popover, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Button from '../../../form/components/Button';
import { ReactComponent as CheckLogo } from '../../../assets/check.svg';
import { useUserCampaignsQuery, useUserPagesQuery } from '../useQueries';
import { useMeQuery } from '../../../rootUseQuery';
import Popup from '../../../components/Popup';
import {
  setPageSelectedCampaign,
  useDeleteProject,
  useSetPageCampaign,
  useUpdateCampaignOnPageStatus,
} from '../useMutations';
import { getActiveCampaigns, getClicksCount, mapProjectsRows } from '../utils';
import { useUserSubscriptionPlan } from '../../../user/useQueries';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeletePage } from '../../translation/useMutations';
import StartIntegrationPopup from '../../customizer/components/StartIntegrationPopup';
import Upgrade, { Step3 } from '../../../upgrade/components/Upgrade';
import { getUserActivePagesCount } from '../utils';
import campaignsPlaceholder from '../../../assets/imgs/popups/campaigns.png';
import { useHistory } from 'react-router-dom';
import { useCustomizerMutationClient } from '../../customizer/useMutations';

let shouldRerender = true;

const columns = [
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'CAMPAIGN',
    dataIndex: 'campaign',
    key: 'campaign',
  },
  {
    title: 'ACTIVE SINCE',
    dataIndex: 'active_since',
    key: 'active_since',
  },
  {
    title: 'IMPRESSIONS',
    dataIndex: 'impressions',
    key: 'impressions',
  },
  {
    title: 'CLICKS',
    dataIndex: 'clicks',
    key: 'clicks',
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
  },
];

const placeHolderRow = [
  {
    key: '1',
    url: 'n.a.',
    campaign: 'n.a.',
    active_since: 'n.a.',
    impressions: 'n.a.',
    clicks: 'n.a.',
    status: 'n.a.',
  },
];

const Projects = ({ routerHistory }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [progress, setProgress] = useState(10);
  let [visible, setVisible] = useState(false);
  let [campaignChange, setCampaignChange] = useState(false);
  let [targetPlan, setTargetPlan] = useState(2);
  let [successPopup, showSuccessPopup] = useState(false);
  let [triggerAddPagePopup, setTriggerAddPagePopup] = useState(false);
  let history = useHistory();

  let [showUpgradePopup, setShowUpgradePopup] = useState(false);

  let [rows, setRows] = useState([]);
  const { data: userPlan, loading: planLoading } = useUserSubscriptionPlan();
  const { data, loading, error } = useUserPagesQuery();
  const {
    data: campaigns,
    loading: campaignsLoading,
  } = useUserCampaignsQuery();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useMeQuery();

  const [setPageCampaign] = useSetPageCampaign();
  const [updateCampaingOnPage] = useUpdateCampaignOnPageStatus();
  const [deletePage] = useDeletePage();
  const [deleteProject] = useDeleteProject();
  const [updateCustomizerClient] = useCustomizerMutationClient();

  let userPagesObjectArray =
    data && data.userPages && data.userPages.object
      ? JSON.parse(data.userPages.object)
      : [];
  let dataSource = placeHolderRow;
  let currentData = useRef();
  currentData.current = userPlan;
  let userRefData = useRef();
  // currentData.current = userData;
  userRefData.current = userData;
  
  const onDelete = async (pageId) => {
    try {
      const result = await deletePage({ variables: { pageId } });
      if (result && result.data && result.data.deletePage) {
        message.success('Your page is successfully deleted');
      } else {
        message.error('Unable to handle your request');
      }
    } catch (error) {
      console.log(error);
      message.error('Unable to handle your request');
    }
  };

  const onCampaignChange = async (rowIndex, campaignId, tempUserPlan) => {
    if (!tempUserPlan) {
      tempUserPlan = currentData.current || userPlan;
    }

    let user = userRefData.current;
    let activeUserPages = getUserActivePagesCount(userPagesObjectArray);
    let isPageAlreadyActive = false;

    userPagesObjectArray
      .filter((p) => p.id === rowIndex)
      .forEach((page) => {
        if (page.campaigns) {
          page.campaigns.forEach((campaign) => {
            if (campaign.status === 'ACTIVE') {
              isPageAlreadyActive = true;
            }
          });
        }
      });

    if (user.me.reachedLimit) {
      if (tempUserPlan.getUserPlan.planId === 1) {
        setTargetPlan(2);
        setShowUpgradePopup(true);
      } else if (tempUserPlan.getUserPlan.planId === 2) {
        setTargetPlan(3);
        setShowUpgradePopup(true);
      } else if (tempUserPlan.getUserPlan.planId === 3) {
        setTargetPlan(4);
        setShowUpgradePopup(true);
      }
    } else if (isPageAlreadyActive) {
      setPageCampaign({
        variables: {
          pageId: rowIndex,
          campaignId: campaignId,
        },
      });
    } else {
      if (
        tempUserPlan.getUserPlan.planId === 1 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 1
      ) {
        setTargetPlan(2);
        setShowUpgradePopup(true);
      } else if (
        tempUserPlan.getUserPlan.planId === 2 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 3
      ) {
        setTargetPlan(3);
        setShowUpgradePopup(true);
      } else if (
        tempUserPlan.getUserPlan.planId === 3 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 5
      ) {
        setTargetPlan(4);
        setShowUpgradePopup(true);
      } else {
        setPageCampaign({
          variables: {
            pageId: rowIndex,
            campaignId: campaignId,
          },
        });
      }
    }
  };

  const onStatusChange = async (
    pageId,
    status,
    campaignId,
    tempUserPlan,
    campaignsOptions,
    rowIndex
  ) => {
    if (!tempUserPlan) {
      tempUserPlan = currentData.current || userPlan;
    }

    if (status === 'DELETE') {
      await onDelete(pageId);
    } else if (status === 'ACTIVE') {
      let user = userRefData.current;
      let activeUserPages = getUserActivePagesCount(userPagesObjectArray);

      if (user.me.reachedLimit) {
        if (tempUserPlan.getUserPlan.planId === 1) {
          setTargetPlan(2);
          setShowUpgradePopup(true);
        } else if (tempUserPlan.getUserPlan.planId === 2) {
          setTargetPlan(3);
          setShowUpgradePopup(true);
        } else if (tempUserPlan.getUserPlan.planId === 3) {
          setTargetPlan(4);
          setShowUpgradePopup(true);
        }
      } else if (
        tempUserPlan.getUserPlan.planId === 1 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 1
      ) {
        setTargetPlan(2);
        setShowUpgradePopup(true);
      } else if (
        tempUserPlan.getUserPlan.planId === 2 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 3
      ) {
        setTargetPlan(3);
        setShowUpgradePopup(true);
      } else if (
        tempUserPlan.getUserPlan.planId === 3 &&
        tempUserPlan.getUserPlan.plan.pages === activeUserPages &&
        userPagesObjectArray.length > 5
      ) {
        setTargetPlan(4);
        setShowUpgradePopup(true);
      } else {
        updateCampaingOnPage({
          variables: {
            pageId: pageId,
            campaignId: campaignId,
            status,
          },
        }).then((e) => {
          // onCampaignChange(pageId, campaignId, status, campaignsOptions);
        });
      }
    } else {
      updateCampaingOnPage({
        variables: {
          pageId: pageId,
          campaignId: campaignId,
          status,
        },
      });
    }
  };

  useEffect(() => {
    if (!shouldRerender && rows.length > 0) {
      return;
    }
    if (
      userPagesObjectArray &&
      userPagesObjectArray.length >= 0 &&
      campaigns &&
      campaigns.getUserCampaigns
    ) {
      if (userPagesObjectArray.length > 0) {
        shouldRerender = false;
      }
      dataSource = mapProjectsRows(
        userPagesObjectArray,
        campaigns,
        onCampaignChange,
        onStatusChange,
        onDelete,
        null,
        null,
        null,
        userPlan
      );
      setRows(dataSource);
    }
  }, [campaigns, data]);

  if (loading || userLoading || campaignsLoading || planLoading) {
    return (
      <LoadingBar
        color="#0052ff"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    );
  }

  let hasFinishedSetup =
    userPagesObjectArray && userPagesObjectArray.length > 0 ? true : false;
  let apiKey = userData && userData.me ? userData.me.apiKey : '';

  let activeCampaigns = getActiveCampaigns(userPagesObjectArray, campaigns);
  let {
    weekClicks,
    weekImpressions,
    clicksGrowth,
    impressionsGrowth,
  } = getClicksCount(userPagesObjectArray);

  const subscriptionCycle =
    userPlan && userPlan.getUserPlan
      ? userPlan.getUserPlan.subscriptionCycle
      : '';

  const deleteProjectHandler = () => {
    (async () => {
      try {
        const result = await deleteProject();
        if (result && result.data && result.data.deleteProject) {
          message.success('Your project successfully deleted');
        } else {
          message.error('Unable to handle your request');
        }
      } catch (error) {
        console.log(error);
        message.error('Unable to handle your request');
      }
    })();
  };

  const content = (
    <div
      style={{
        width: '180px',
        borderRadius: '2px',
        border: 'solid 1px #ccd2d8',
        backgroundColor: 'white',
        paddingLeft: '19px',
        paddingTop: '22px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ cursor: 'pointer' }} onClick={deleteProjectHandler}>
        <DeleteOutlined
          style={{ width: '10px', height: '11px', color: '#0a2540' }}
        />
        <span style={{ marginLeft: '16px', color: '#0a2540' }}>
          Delete Project
        </span>
      </div>
    </div>
  );

  return (
    <div className="projects-page-wrapper">
      <div className="projects-page-header">
        <div className="ls">
          <div className="projects-inner-title">Project</div>
          <div className="project-name">
            {hasFinishedSetup
              ? userPagesObjectArray &&
                userPagesObjectArray[0] &&
                userPagesObjectArray[0].pageUrl.substring(
                  0,
                  userPagesObjectArray[0].pageUrl.indexOf('/') > 0
                    ? userPagesObjectArray[0].pageUrl.indexOf('/')
                    : userPagesObjectArray[0].pageUrl.length
                )
              : 'n.a.'}
          </div>
          <div
            id="project-status"
            className="project-status"
            style={{ display: 'flex' }}
          >
            {hasFinishedSetup ? (
              <>
                <div style={{ width: '25px', height: '25px' }}>
                  <CheckLogo
                    style={{
                      width: '16px',
                      height: '16px',
                      fill: '#0052ff',
                      borderRadius: '50%',
                    }}
                  />
                </div>

                <span>INSTALLATION COMPLETED</span>
              </>
            ) : (
              <>
                <div
                  className="ring-container"
                  style={{ marginTop: '10px', width: '25px', height: '25px' }}
                >
                  <div
                    className="ringring"
                    style={{ top: '0px', left: '0px' }}
                  ></div>
                  <div
                    className="circle"
                    style={{ top: '5px', left: '5px' }}
                  ></div>
                </div>
                <span>INSTALLATION NOT COMPLETED</span>
              </>
            )}
          </div>
        </div>
        <div className="rs">
          <Button
            className="wf-btn-primary"
            children={hasFinishedSetup ? 'Add Page' : 'START INTEGRATION'}
            onClick={async (e) => {
              setShowPopup(true);
            }}
          />

          <div className="page-popover">
            <Popover
              arrowContent=""
              placement="bottomRight"
              content={content}
              trigger="click"
              visible={visible}
              onVisibleChange={(visible) => setVisible(visible)}
            >
              <Button className={visible ? 'active' : ''}>...</Button>
            </Popover>
          </div>
        </div>
      </div>
      <div className="projects-page-sub-header">
        <div className="p-an-w">
          <div className="p-an-l">Impressions (7d)</div>
          <div
            className="p-an-v"
            style={{
              color:
                userData && userData.me && userData.me.reachedLimit
                  ? '#ee4f43'
                  : '#0a2540',
            }}
          >
            {weekImpressions ? weekImpressions : 'n.a.'}
            <sup
              style={{
                color:
                  userData && userData.me && userData.me.reachedLimit
                    ? '#ee4f43'
                    : '#0052ff',
              }}
            >
              {impressionsGrowth
                ? impressionsGrowth > 0
                  ? `+${impressionsGrowth}`
                  : impressionsGrowth < 0
                  ? `${impressionsGrowth}`
                  : impressionsGrowth
                : 'n.a.'}
              %
            </sup>
          </div>
        </div>

        <div className="p-an-w">
          <div className="p-an-l">Clicks (7d)</div>
          <div className="p-an-v">
            {weekClicks ? weekClicks : 'n.a.'}
            <sup>
              {clicksGrowth
                ? clicksGrowth > 0
                  ? `+${clicksGrowth}`
                  : clicksGrowth < 0
                  ? `${clicksGrowth}`
                  : clicksGrowth
                : 'n.a.'}
              %
            </sup>
          </div>
        </div>

        <div className="p-an-w">
          <div className="p-an-l">Sites</div>
          <div className="p-an-v">
            {' '}
            {userPagesObjectArray ? userPagesObjectArray.length : 'n.a.'}
          </div>
        </div>

        <div className="p-an-w">
          <div className="p-an-l">Campaigns</div>
          <div className="p-an-v">
            {campaigns && campaigns.getUserCampaigns
              ? campaigns.getUserCampaigns.length
              : 'n.a.'}
          </div>
        </div>

        <div className="p-an-w">
          <div className="p-an-l">Active</div>
          <div className="p-an-v last">
            {activeCampaigns ? `${activeCampaigns}%` : 'n.a.'}
          </div>
        </div>
      </div>
      {userData && userData.me && userData.me.reachedLimit && (
        <div
          style={{
            height: '83px',
            border: 'solid 1px rgba(227, 232, 238, 0.42)',
            backgroundColor: '#ee4f43',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '45px',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: 2.94,
              color: 'white',
            }}
          >
            All banners are paused. You reached your plan limits. Please upgrade
            in order to continue using bannernote.
          </span>
          <span>
            <button
              style={{
                outline: 'none',
                width: '111px',
                margin: '0px 0 2px 70px',
                borderRadius: '2px',
                boxShadow: '0 2px 20px -5px #e8eaef',
                backgroundColor: 'white',
                border: 0,
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 'bold',
                lineHeight: 3.36,
                color: '#ee4f43',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                if (userPlan.getUserPlan.planId === 1) {
                  setTargetPlan(2);
                  setShowUpgradePopup(true);
                } else if (userPlan.getUserPlan.planId === 2) {
                  setTargetPlan(3);
                  setShowUpgradePopup(true);
                } else if (userPlan.getUserPlan.planId === 3) {
                  setTargetPlan(4);
                  setShowUpgradePopup(true);
                }
              }}
            >
              UPGRADE
            </button>
          </span>
        </div>
      )}
      <div className="projects-page-table">
        {rows.length === 0 ? (
          <div className="empty-placeholder">
            <div className="ls">
              <img src={campaignsPlaceholder} style={{ width: '100%' }} />
            </div>
            <div className="rs">
              <div className="title">
                Start with the Integration
                <br /> as soon as you are ready.
              </div>
              <div className="btns">
                <Button
                  className="wf-btn-primary"
                  children={hasFinishedSetup ? 'Add Page' : 'START INTEGRATION'}
                  onClick={async (e) => {
                    setShowPopup(true);
                  }}
                />

                <Button
                  className="wf-btn-secondary"
                  children="CUSTOMIZE BANNER"
                  onClick={async (e) => {
                    e.preventDefault();
                    await updateCustomizerClient({
                      variables: {
                        isOpen: true,
                      },
                    });

                    history.push('/customizer');
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            loading={loading}
          />
        )}
      </div>
      <div style={{ display: 'block', width: '100%', height: '100px' }}></div>
      {showPopup && (
        <Popup
          component={() => {
            return (
              <StartIntegrationPopup
                successCB={async () => {
                  setShowPopup(false);
                }}
                apiKey={apiKey}
              />
            );
          }}
          closePopup={async (e) => {
            setShowPopup(false);
          }}
          style={{
            minHeight: '840px',
            minWidth: '986px',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
            padding: 0,
            width: '80%',
            overflowY: 'hidden',
          }}
        />
      )}

      {showUpgradePopup && (
        <Popup
          component={() => {
            return (
              <Upgrade
                successCB={async () => {
                  setShowUpgradePopup(false);
                  // setShowPopup(true);
                  showSuccessPopup(true);
                }}
                // preStep={userStatus !==   'BASIC' && userStatus !== 'PREMIUM' ? 3 : 1}
                subscriptionCycle={subscriptionCycle}
                targetPlan={
                  userPlan &&
                  userPlan.getUserPlan &&
                  userPlan.getUserPlan.plan &&
                  userPlan.getUserPlan.plan.id
                    ? userPlan.getUserPlan.plan.id < 4 &&
                      userPlan.getUserPlan.plan.id >= 1
                      ? userPlan.getUserPlan.plan.id + 1
                      : userPlan.getUserPlan.plan.id
                    : 2
                }
              />
            );
          }}
          closePopup={async (e) => {
            setShowUpgradePopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}

      {successPopup && (
        <Popup
          component={() => {
            return (
              <Step3
                successCB={async () => {
                  showSuccessPopup(false);
                  if (triggerAddPagePopup) {
                    setTriggerAddPagePopup(false);
                    setShowPopup(true);
                  }
                }}
              />
            );
          }}
          closePopup={async (e) => {
            showSuccessPopup(false);
            if (triggerAddPagePopup) {
              setTriggerAddPagePopup(false);
              setShowPopup(true);
            }
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}
    </div>
  );
};

export default Projects;
