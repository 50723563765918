import React from 'react';
import { POSITION_OPTIONS } from '../../../layout/constants';
import CustomSelect from './CustomSelect';
import Input from '../../../form/components/Input';

import { useSelector, useDispatch } from 'react-redux';
import {
  addPosition,
  addCustomPositionDiv,
} from '../../../redux/customizerSidebarSlice';

const PositionComponent = () => {
  const dispatch = useDispatch();
  const { position, customPositionDiv } = useSelector(
    (state) => state.customizer.customizerData
  );

  let mappedPositionObject;
  if (position.length === 0) {
    mappedPositionObject = [{ label: 'FIXED', value: 'FIXED' }];
  } else {
    mappedPositionObject = POSITION_OPTIONS.filter((f) => f.value === position);
  }

  return (
    <>
      <CustomSelect
        label="Position"
        activeMenuItem={mappedPositionObject[0]}
        menuItems={POSITION_OPTIONS}
        actionFunc={addPosition}
      />

      <div className="f-item">
        <div className="tit">Custom</div>

        <Input
          value={customPositionDiv}
          placeholder="e.g. bannerbelow"
          onChange={(e) => {
            dispatch(addCustomPositionDiv(e.target.value));
          }}
          style={{
            width: '100%',
            height: '57px',
          }}
        />

        <div
          style={{
            marginTop: '16px',
            fontSize: '14px',
            color: '#0a2540',
            opacity: '0.36',
          }}
        >
          * If id of element will not be found on the page, default position
          will be used
        </div>
      </div>
      <div
        className="f-item"
        style={{
          display: 'block',
          width: 'fit-content',
          margin: '10px auto 0',
        }}
      >
      </div>
    </>
  );
};
export default PositionComponent;
