import gql from 'graphql-tag';

export const REFETCH_PAGE = gql`
    mutation refetchPage($pageId: Int!) {
        refetchPage(pageId: $pageId) {
            id
        }
    }
`;

export const DELETE_PAGE = gql`
    mutation deletePage($pageId: Int!) {
        deletePage(pageId: $pageId)
    }
`;