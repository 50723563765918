export default {
  userData: {
    __typename: "userData",
    id: "",
    fullName: "",
    email: "",
    role: "",
    isNew: true,
  },
  customizer: {
    __typename: "customizer",
    isOpen: false,

    style: "TOP_BANNER",
    bannerText: "",
    fontFamily: "Open Sans",
    fontWeight: "700",
    titleTextAlign: "LEFT",
    subtitleText: "",
    subtitleFontFamily: "Open Sans",
    subtitleFontWeight: "700",
    subtitleFontSize: 12,
    subtitleTextAlign: "LEFT",
    // Button
    btnText: "READ UPDATES",
    link: "https://google.com",
    openingStyle: "_blank",
    buttonStyle: "framed",
    buttonColor: "transparent",
    textColor: "",
    closeButton: "show",
    buttonAlignment: "left",
    buttonPadding: "0 12px",

    // Color
    backgroundColor: "",
    fontColor: "",

    // branding
    branding: "WITH_BRANDING",

    // active campaign
    activeCampaign: null,

    size: "SMALL",
    borderRadius: 0,
    position: "",
    customPositionDiv: "",
    animationStyle: "NONE",
    animationDelay: "NO_DELAY",
    animationEffect: "NONE",
    animationType: "",
    attentionSeeker: "NONE",
    attentionCadence: "NONE",
    cadenceInterval: 2,
    fontSize: null,
    modalStyle: "SIMPLE",
    modalAlignment: "LEFT",
    modalBorderRadius: 15,
    modalShadow: "",
    modalImage: "",
    isLoading: true,
  },
  upgrade: {
    __typename: "upgrade",
    shouldShowUpgradePopup: false,
    targetPlan: 2, // go to starter plan by default
    shouldResetUpgradeData: null,
    tempUserBranding: null,
    tempPageUrl: null,
  },
};
