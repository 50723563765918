import React from 'react';

const DesktopIcon = ({ switcher }) => {
  return (
    <svg viewBox="0 0 512 512" style={{ width: '28px', height: '19px' }}>
      <path
        d="M501.333,362.667h-32V128c0-23.531-19.135-42.667-42.667-42.667H85.333c-23.531,0-42.667,19.135-42.667,42.667v234.667
			h-32C4.771,362.667,0,367.437,0,373.333V384c0,23.531,19.135,42.667,42.667,42.667h426.667C492.865,426.667,512,407.531,512,384
			v-10.667C512,367.437,507.229,362.667,501.333,362.667z M64,128c0-11.76,9.573-21.333,21.333-21.333h341.333
			c11.76,0,21.333,9.573,21.333,21.333v234.667H309.333H202.667H64V128z M469.333,405.333H42.667
			c-11.76,0-21.333-9.573-21.333-21.333h32H198.25l7.542,7.542c2,2,4.708,3.125,7.542,3.125h85.333c2.833,0,5.542-1.125,7.542-3.125
			L313.75,384h144.917h32C490.667,395.76,481.094,405.333,469.333,405.333z"
        fill={switcher ? '#0052ff' : 'rgba(10, 37, 64, 0.12)'}
      />
    </svg>
  );
};
export default DesktopIcon;
