import React from 'react';
import centerLogo from '../assets/deed@2x.png';
import { ActiveTag } from './components/Upgrade';
export const mapPlans = (
    plansData,
    cycle = 'YEARLY',
    status,
    targetPlan,
    subscriptionCycle,
    currentPlan
) => {
    let options = [];
    if (plansData && plansData.plans && plansData.plans.length) {
        let plansOptions = JSON.parse(JSON.stringify(plansData.plans));
        const sortedOptions = plansOptions.sort((a, b) => a.id - b.id);

        // const filteredOptions = sortedOptions.filter((o) => o.id > 1);
        sortedOptions.forEach((plan) => {
            const option = {};
            if (cycle === 'MONTHLY' && subscriptionCycle === 'monthly' && plan.id < targetPlan) {
                option.isdisabled = true;
            } else if (plan.id <= currentPlan.id && cycle !== 'MONTHLY') {
                option.isdisabled = true;
            } else {
                option.isdisabled = plan.id === currentPlan.id && currentPlan.id === 1;
            }
            option.value =
                cycle === 'YEARLY'
                    ? `${plan.id}/${plan.yearlyPriceId}`
                    : `${plan.id}/${plan.monthlyPriceId}`;

            option.label = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 0 10px 0',
                        justifyContent: 'space-between',
                        ...(((currentPlan.id === 1 && plan.id === 1) ||
                            (currentPlan.id === plan.id &&
                                cycle.toLowerCase() === subscriptionCycle)) && {
                            backgroundColor: '#eaf1ff',
                        }),
                    }}
                >
                    <div className="center-logo">
                        <img src={centerLogo} width="94px" style={{ marginLeft: '-15%' }} />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            textAlign: 'left',
                        }}
                    >
                        <span
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: !option.isdisabled ? '#0a2540' : '#ccc',
                                fontFamily: 'Open Sans',
                                ...(((currentPlan.id === 1 && plan.id === 1) ||
                                    (currentPlan.id === plan.id &&
                                        cycle.toLowerCase() === subscriptionCycle)) && {
                                    color: 'rgba(0, 82, 255, 1)',
                                }),
                            }}
                        >
                            {capitalizeFirstLetter(plan.type)}
                            <span
                                style={{
                                    marginLeft: '5px',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '21.79px',
                                    marginLeft: '10px',
                                    color: !option.isdisabled ? '#0a2540' : '#ccc',
                                    ...(((currentPlan.id === 1 && plan.id === 1) ||
                                        (currentPlan.id === plan.id &&
                                            cycle.toLowerCase() === subscriptionCycle)) && {
                                        color: 'rgba(0, 82, 255, 1)',
                                    }),
                                }}
                            >
                                (
                                {`${plan.id === 4 ? '∞' : plan.pages} Pages active, ${
                                    plan.id === 4 ? '∞' : nFormatter(plan.impressions)
                                } impressions/m${plan.id > 1 ? ', removed branding' : ''}`}
                                )
                                {((currentPlan.id === plan.id &&
                                    cycle.toLowerCase() === subscriptionCycle) ||
                                    (currentPlan.id === 1 && plan.id === 1)) && <ActiveTag />}
                            </span>
                        </span>

                        <span
                            style={{
                                marginLeft: '10px',
                                fontFamily: 'Open Sans',
                                fontSize: '20px',
                                color: !option.isdisabled ? '#0a2540' : '#ccc',
                                fontWeight: 'bold',
                                float: 'right',
                                marginRight: '40px',
                                ...(((currentPlan.id === 1 && plan.id === 1) ||
                                    (currentPlan.id === plan.id &&
                                        cycle.toLowerCase() === subscriptionCycle)) && {
                                    color: 'rgba(0, 82, 255, 1)',
                                }),
                            }}
                        >
                            {cycle === 'YEARLY'
                                ? `$${plan.yearlyPriceAmount}/m`
                                : `$${plan.monthlyPriceAmount}/m`}
                        </span>
                    </div>
                </div>
            );

            options.push(option);
        });
    }

    return options;
};

export const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const nFormatter = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
};
