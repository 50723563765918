import React from 'react';
import { Row, Icon } from 'antd';
import { Grid } from 'svg-loaders-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import InputField from '../../../form/components/InputField';
import Button from '../../../form/components/Button';
import Link from '../../../form/components/Link';
import { useLogin } from '../useLogin';
import { showAllGraphQLErrors } from '../../../helper/graphqlErrors';
import { auth } from '../../auth';
import { isAdmin } from '../../utils';
import GoogleAuthHooks from '../../GoogleAuthHooks';

const initialValues = {
  email: '',
  password: '',
};

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('*Required'),
  password: Yup.string().required('*Required'),
});

const Login = ({ routerHistory }) => {
  const [login] = useLogin();
  return (
    <>
      <GoogleAuthHooks btnText="Sign In with Google" />

      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await login({ variables: { ...values } });
            if (result) {
              auth.logIn(result.data.login.token);
              window.location.assign('/');

              // if (isAdmin(result.data.login.user.role))
              //     if (result.data.login.user.isNew) {
              //         window.location.assign('/');
              //     } else {
              //         window.location.assign('/');
              //     }
              // else {
              //     // go to dashboard
              //     window.location.assign('/');
              // }
            }
          } catch (error) {
            setSubmitting(false);
            showAllGraphQLErrors(error.graphQLErrors);
          }
        }}
      >
        {({ values, isSubmitting, dirty, errors }) => (
          <Form>
            <Row className="auth-row">
              <InputField name="email" type="text" label="Email" />
            </Row>
            <Row className="auth-row">
              <InputField name="password" type="password" label="Password" />
            </Row>
            <Row className="forgot-pwd-link">
              <Link to="/reset-password" label="Forgot your password?" />
            </Row>
            <Row>
              <Button
                htmlType="submit"
                type="primary"
                disabled={isSubmitting}
                className={classNames('wf-btn-primary', {
                  active: dirty && Object.keys(errors).length === 0,
                })}
              >
                {isSubmitting ? (
                  <Grid style={{ width: '17px', height: '17px' }} />
                ) : (
                  <>LOG IN</>
                )}
              </Button>
            </Row>
            <p className="login-wrapper__footer">
              <span>Don’t have an account yet?</span>{' '}
              <a href="/signup">Signup</a>
            </p>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
