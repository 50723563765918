import gql from 'graphql-tag';


export const UPDATE_USER_MUTATION = gql`
    mutation updateUser($isNew: Boolean) {
        updateUser(isNew: $isNew)
    }
`;

export const USER_SUBSCRIPTION_PLAN = gql`
    query getUserPlan {
        getUserPlan {
            stripeCustomerId
            subscription
            planId
            status
            isInTrialPeriod
            trialEnds
            subscriptionCycle
            plan {
                id
                type
                monthlyPriceId
                yearlyPriceId
                monthlyPriceAmount
                yearlyPriceAmount
                pages
                impressions
            }
        }
    }
`;

export const CUSTOMER_CARDS = gql`
    query getCustomerCard {
        getCustomerCard
    }
`;
