import React from 'react';
import Button from '../../../form/components/Button';
import welcomeLogo from '../../../assets/imgs/popups/welcome.png';

const WelcomePopup = ({ successCB }) => {
    return (
        <div className="welcome-popup">
            <div className="ls">
                <div className="ls-content">
                    <div className="ls-title">bannernote</div>
                    <div className="ls-inner-content">
                        <img src={welcomeLogo} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className="rs">
                <div className="rs-title">Welcome to Bannernote</div>
                <div className="rs-desc">
                    Start to customize your banner in the customization
                    <br /> tool and complete the setup afterwards.
                </div>
                <Button
                    children="START CUSTOMIZATION"
                    className="wf-btn-primary"
                    onClick={() => successCB()}
                />
            </div>
        </div>
    );
};

export default WelcomePopup;
