import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './select_w_arrows.module.scss';
import ArrowIcon from '../ArrowIcon';
import useOnClickOutside from '../../hooks/useClickOutside';

const SelectWithDoubleArrows = ({
  activeMenuItem,
  menuItems,
  callback,
  type,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const menuRef = useRef();

  const openMenuHandler = () => {
    setIsOpenMenu(!isOpenMenu);
  };
  const closeMenuHandler = () => {
    setIsOpenMenu(false);
  };
  const selectHandler = (itemValue) => {
    closeMenuHandler();
    callback(itemValue);
  };

  useOnClickOutside(menuRef, closeMenuHandler);

  return (
    <div ref={menuRef} className={styles.selesctBlock}>
      {type === 'status' && !isOpenMenu && (
        <div className={styles.iconContainer}>
          <span
            className={classNames(
              styles.iconMenuItem,
              activeMenuItem.label === 'ACTIVE' && styles.iconActiveName
            )}
          />
        </div>
      )}
      <input
        className={classNames(
          styles.customSelect,
          type === 'status' && styles.statusSelect,
          isOpenMenu && styles.openedState
        )}
        value={isOpenMenu ? 'Choose' : activeMenuItem.label}
        onClick={openMenuHandler}
        placeholder={isOpenMenu ? 'Choose' : activeMenuItem.label}
        readOnly
      />
      <div className={styles.selectArrowContainer}>
        <ArrowIcon classIcon={styles.upArrowIcon} switcher={isOpenMenu} />
        <ArrowIcon classIcon={styles.downArrowIcon} switcher={isOpenMenu} />
      </div>
      <ul
        className={classNames(
          styles.selectMenu,
          isOpenMenu && styles.openedMenu
        )}
      >
        {menuItems.map((item, idx) => {
          return (
            <li
              key={`${item.value} + ${idx}`}
              className={classNames(
                styles.selectMenuItem,
                activeMenuItem.value === item.value && styles.activeItemMenu
              )}
              data-item={item.value}
              onClick={() => selectHandler(item.value)}
            >
              {type === 'status' && item.label !== 'DELETE' && (
                <span
                  className={classNames(
                    styles.iconMenuItem,
                    item.label === 'ACTIVE' && styles.iconActiveName
                  )}
                />
              )}
              {type === 'status' && item.label === 'DELETE' && (
                <DeleteOutlined className={styles.deleteIcon} />
              )}
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SelectWithDoubleArrows;
