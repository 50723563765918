import React, { useEffect } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../helper/refreshTokenSetup';
import { auth } from './auth';
import { gapi } from 'gapi-script';
import { useSignupGoogle } from './signup/useMutations';
import { showAllGraphQLErrors } from '../helper/graphqlErrors';

const clientId =
  '627603514642-2nu5og7kb64relqi1nan8hcfd490rp3r.apps.googleusercontent.com';

const GoogleAuthHooks = ({ btnText }) => {
  const [signup] = useSignupGoogle();
  const onSuccess = async (res) => {
    console.log('Login Success: currentUser:', res);
    try {
      const result = await signup({
        variables: {
          token: res.tokenId
        }
      });
      if (result) {
        console.log(result);
        auth.setAccessToken(result.data.signupGoogle.token);
        // routerHistory.push('/customizer', { showWelcomePopup: true });
        window.location.assign('/customizer');
      }
    } catch (error) {
      // setSubmitting(false);
      showAllGraphQLErrors(error.graphQLErrors);
    }

    // auth.setAccessToken(res.tokenId);
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  });
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  });

  return (
    <>
      <button onClick={signIn} className="btn-google">
        {btnText}
      </button>
      <div className="auth-divider">or</div>
    </>
  );
};

export default GoogleAuthHooks;
