import React, { useLayoutEffect } from 'react';
import { auth } from '../signupLogin/auth';
import { Link } from 'react-router-dom';
import centerLogo from '../assets/imgs/signupLogin/templates.png';
import logo from '../assets/imgs/signupLogin/logo.png';
import rightChevron from '../assets/right-chevron.png';

const AuthPageLayout = ({
  history,
  Component,
  title,
  subTitle,
  headerLink,
  ...rest
}) => {
  useLayoutEffect(() => {
    const token = auth.getAccessToken();
    if (token !== null) {
      history.push('/');
    }
  }, []);
  const clientId =
    '627603514642-2nu5og7kb64relqi1nan8hcfd490rp3r.apps.googleusercontent.com';
  return (
    <div className="auth-page-layout">
      <div class="auth-page-container">
        <div className="login-wrapper">
          <img
            style={{ width: '46px', height: '46px' }}
            src={logo}
            alt="logo"
          />
          <h3 className="login-wrapper__title">{title}</h3>
          {subTitle && <p className="login-wrapper__subtitle">{subTitle}</p>}

          <Component routerHistory={history} clientId={clientId} {...rest} />
          <div className="auth-footer">
            <span>Bannernote.com ® 2022</span>
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>
      <div className="auth-page-template">
        <div className="center-logo">
          <img src={centerLogo} alt="template" />
        </div>
        <div>
          <h2 className="auth-template__title">Explore our Templates</h2>
          <p className="auth-template__subtitle">
            Create highly converting campaigns from one dashboard{' '}
            <strong>without distracting your engineers.</strong>
          </p>
          <Link className="explore-link" to={'/'}>
            Explore Now
            <img
              style={{ marginLeft: '14px' }}
              src={rightChevron}
              alt="right arrow"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
