import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Close } from '../assets/close.svg';

const Popup = ({ text, closePopup, component: Component, style }) => {
    return (
        <a
        id="inline-popups"
            className={classNames('popup-box')}
            style={{
                backgroundColor: 'rgba(125, 155, 186, 0.38)',
            }}
        >
            <div className="box rodal-zoom-enter" style={{ ...style, animationDuration: '200ms' }}>
                <span className="close-icon" onClick={closePopup}>
                    <Close style={{ width: '10px', height: '10px', opacity: '0.28' }} />
                </span>
                <Component />
            </div>
        </a>
    );
};

export default Popup;
