import { useQuery } from '@apollo/react-hooks';
import { CUSTOMER_CARDS, USER_SUBSCRIPTION_PLAN } from './gql';

export const useUserSubscriptionPlan = () =>
    useQuery(USER_SUBSCRIPTION_PLAN, {
        fetchPolicy: 'cache-and-network',
    });
export const useCustomerCarsdQuery = () =>
    useQuery(CUSTOMER_CARDS, {
        fetchPolicy: 'cache-and-network',
    });
