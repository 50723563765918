import React, { memo } from 'react';
import { Radio } from 'antd';
import styleTop from '../../../assets/imgs/sidebar/style-top.png';
import stylePopup from '../../../assets/imgs/sidebar/style-pop.png';
import styleRight from '../../../assets/imgs/sidebar/style-rig.png';
import { useSelector, useDispatch } from 'react-redux';
import { addStyleBanner } from '../../../redux/customizerSidebarSlice';

const StyleComponent = () => {
  const dispatch = useDispatch();
  const customizerDataObj = useSelector(
    (state) => state.customizer.customizerData
  );

  return (
    <>
      <div className="customizer-menu-group">
        <Radio.Group
          value={customizerDataObj.style}
          onChange={(e) => {
            dispatch(addStyleBanner(e.target.value));
          }}
        >
          <Radio.Button value="TOP_BANNER">
            TOP BANNER
            <img
              src={styleTop}
              style={{
                width: '150px',
                height: '95px',
                marginLeft: '10px',
                marginBottom: '10px',
              }}
            />
          </Radio.Button>

          <Radio.Button disabled={true} value="POP_BANNER">
            MIDDLE (SOON)
            <img
              src={stylePopup}
              style={{
                width: '150px',
                height: '95px',
                marginLeft: '10px',
                marginBottom: '10px',
              }}
            />
          </Radio.Button>

          <Radio.Button value="BOTTOM_BANNER">
            BOTTOM BANNER
            <img
              src={styleRight}
              style={{
                width: '150px',
                height: '95px',
                marginLeft: '10px',
                marginBottom: '10px',
              }}
            />
          </Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
};
// export default memo(StyleComponent);
export default StyleComponent;
