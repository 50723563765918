import { useMutation } from "@apollo/react-hooks";
import {
    CAMPAIGN_CUSTOMIZER_MUTATION,
    CREATE_CAMPAIGN,
    CUSTOMIZER_MUTATION,
    CUSTOMIZER_MUTATION_SERVER,
    UPLOAD_IMAGE,
    CUSTOMIZER_ACTIVE_CAMPAIGN
} from './gql';

export const useCustomizerMutationClient = () =>
  useMutation(CUSTOMIZER_MUTATION, {
    // refetchQueries: ['customizerQuery'],
  });
export const useCustomizerMutation = () =>
  useMutation(CUSTOMIZER_MUTATION_SERVER, {
    refetchQueries: ["getUserPlan"],
  });

export const useUpdateCampaignCustomizer = () =>
  useMutation(CAMPAIGN_CUSTOMIZER_MUTATION);

export const useCreateCampaign = () =>
  useMutation(CREATE_CAMPAIGN, {
    refetchQueries: ["getUserCampaigns"],
  });

export const useUpdateActiveCampaign = () => useMutation(CUSTOMIZER_ACTIVE_CAMPAIGN)

export const useUploadImage = () => useMutation(UPLOAD_IMAGE)
