export const FONTS = [
  { label: "INTER", value: "Inter" },
  { label: "OPEN SANS", value: "Open Sans" },
  { label: "POPPINS", value: "Poppins" },
  { label: "MONTSERRAT", value: "Montserrat" },
  { label: "MERRIWEATHER", value: "Merriweather" },
  { label: "ABRIL", value: "Abril Fatface" },
  { label: "PLAYFAIR", value: "Playfair" },
  { label: "ROBOTO MONO", value: "Roboto Mono" },
  { label: "ROBOTO", value: "Roboto" },
  { label: "LATO", value: "Lato" },
  { label: "Slabo", value: "Slabo 13px" },
];

export const FONTS_STYLES = [
  { label: "LIGHT", value: "300" },
  { label: "Regular/Medium", value: "400" },
  { label: "BOLD", value: "700" },
];

export const FONTS_SIZE = [
  { label: "10px", value: 10 },
  { label: "11px", value: 11 },
  { label: "12px", value: 12 },
  { label: "13px", value: 13 },
  { label: "14px", value: 14 },
  { label: "15px", value: 15 },
  { label: "16px", value: 16 },
];

export const OPENING_STYLE = [
  { label: "NEW TAB", value: "_blank" },
  { label: "SAME TAB", value: "same" },
];

export const MODAL_ALIGNMENT = [
  { label: "LEFT", value: "LEFT" },
  { label: "RIGHT", value: "RIGHT" },
  { label: "CENTER", value: "CENTER" },
];

export const BUTTON_ALIGNMENT = [
  { label: "LEFT", value: "left" },
  { label: "RIGHT", value: "right" },
  { label: "CENTER", value: "center" },
];

export const BUTTON_STYLE = [
  { label: "FRAMED", value: "framed" },
  { label: "FILLED", value: "filled" },
  { label: "TEXT ONLY", value: "text_only" },
  { label: "UNDERLINED ", value: "underlined" },
  { label: "ARROW", value: "arrow" },
  { label: "NO BUTTON", value: "no_button" },
];

export const CLOSE_BTN = [
  { label: "SHOW", value: "show" },
  { label: "HIDE", value: "hide" },
];

export const SIZE_OPTIONS = [
  { label: "SMALL", value: "SMALL" },
  { label: "MEDIUM", value: "MEDIUM" },
  { label: "LARGE", value: "LARGE" },
];

export const POSITION_OPTIONS = [
  { label: "FIXED", value: "FIXED" },
  { label: "ABSOLUTE", value: "ABSOLUTE" },
];

export const ANIMATION_STYLE_OPTIONS = [
  { label: "BOUNCE", value: "BOUNCE" },
  { label: "FADE", value: "FADE" },
  { label: "ZOOM", value: "ZOOM" },
  { label: "SLIDE DOWN", value: "SLIDE_DOWN" },
  { label: "NONE", value: "NONE" },
];

export const ANIMATION_DELAY_OPTIONS = [
  { label: "NO DELAY", value: "NO_DELAY" },
  { label: "2SEC", value: "2" },
  { label: "5SEC", value: "5" },
  { label: "10SEC", value: "10" },
  { label: "30SEC", value: "30" },
];

export const ANIMATION_BOUNCE_OPTIONS = [
  { label: "BACK IN", value: "BOUNCE_IN" },
  { label: "BACK IN DOWN", value: "BOUNCE_IN_DOWN" },
  { label: "BACK IN LEFT", value: "BOUNCE_IN_LEFT" },
  { label: "BACK IN RIGHT", value: "BOUNCE_IN_RIGHT" },
  { label: "BACK IN UP", value: "BOUNCE_IN_UP" },
  { label: "NONE", value: "NONE" },
];

export const ANIMATION_FADE_OPTIONS = [
  { label: "FADE IN", value: "FADE_IN" },
  { label: "FADE IN DOWN", value: "FADE_IN_DOWN" },
  { label: "FADE IN DOWN BIG", value: "FADE_IN_DOWN_BIG" },
  { label: "FADE IN LEFT", value: "FADE_IN_LEFT" },
  { label: "FADE IN LEFT BIG", value: "FADE_IN_LEFT_BIG" },
  { label: "FADE IN RIGHT", value: "FADE_IN_RIGHT" },
  { label: "FADE IN RIGHT BIG", value: "FADE_IN_RIGHT_BIG" },
  { label: "FADE IN UP", value: "FADE_IN_UP" },
  { label: "FADE IN UP BIG", value: "FADE_IN_UP_BIG" },
  { label: "FADE IN TOP LEFT", value: "FADE_IN_TOP_LEFT" },
  { label: "FADE IN TOP RIGHT", value: "FADE_IN_TOP_RIGHT" },
  { label: "FADE IN BOTTOM LEFT", value: "FADE_IN_BOTTOM_LEFT" },
  { label: "FADE IN BOTTOM RIGHT", value: "FADE_IN_BOTTOM_RIGHT" },
  { label: "NONE", value: "NONE" },
];

export const ANIMATION_ZOOM_OPTIONS = [
  { label: "ZOOM IN", value: "ZOOM_IN" },
  { label: "ZOOM IN DOWN", value: "ZOOM_IN_DOWN" },
  { label: "ZOOM IN LEFT", value: "ZOOM_IN_LEFT" },
  { label: "ZOOM IN RIGHT", value: "ZOOM_IN_RIGHT" },
  { label: "ZOOM IN UP", value: "ZOOM_IN_UP" },
  { label: "NONE", value: "NONE" },
];

export const ANIMATION_SLIDING_OPTIONS = [
  { label: "SLIDE IN DOWN", value: "SLIDE_IN_DOWN" },
  { label: "SLIDE IN LEFT", value: "SLIDE_IN_LEFT" },
  { label: "SLIDE IN RIGHT", value: "SLIDE_IN_RIGHT" },
  { label: "SLIDE IN UP", value: "SLIDE_IN_UP" },
  { label: "NONE", value: "NONE" },
];

export const MODAL_STYLE_OPTIONS = [
  { label: "Simple", value: "SIMPLE" },
  { label: "Split", value: "SPLIT" },
  { label: "With Image", value: "WITH_IMAGE" },
];

export const ANIMATION_OPTIONS_ARRAY = [
  { key: "BOUNCE", value: ANIMATION_BOUNCE_OPTIONS },
  { key: "FADE", value: ANIMATION_FADE_OPTIONS },
  { key: "ZOOM", value: ANIMATION_ZOOM_OPTIONS },
  { key: "SLIDE_DOWN", value: ANIMATION_SLIDING_OPTIONS },
];

export const ATTENTION_SEEKER_OPTIONS = [
  { label: "BOUNCE", value: "BOUNCE" },
  { label: "FLASH", value: "FLASH" },
  { label: "PULSE", value: "PULSE" },
  { label: "RUBBER BAND", value: "RUBBER_BAND" },
  { label: "SHAKE X", value: "SHAKE_X" },
  { label: "SHAKE Y", value: "SHAKE_Y" },
  { label: "HEAD HAKE", value: "HEAD_SHAKE" },
  { label: "SWING", value: "SWING" },
  { label: "TADA", value: "TADA" },
  { label: "WOBBLE", value: "SWING" },
  { label: "JELLO", value: "JELLO" },
  { label: "HEART BEAT", value: "HEART_BEAT" },
  { label: "NONE", value: "NONE" },
];

export const ATTENTION_CADENCE_OPTIONS = [
  { label: "NONE", value: "NONE" },
  { label: "Interval", value: "INTERVAL" },
];

export const ANIMATION_EFFECT_OPTIONS = [
  { label: "NONE", value: "NONE" },
  { label: "GLITTER", value: "GLITTER" },
];

export const customStyle = (text) => {
  return {
    option: (base, data) => {
      return {
        ...base,
        backgroundColor: "#e8eaef",
        color: "#0a2540",
        fontSize: "12px",
        letterSpacing: "0.43px",
        fontWeight: "bold",
        "&:active": { backgroundColor: "rgba(227, 232, 238, 0.42)" },
        "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.01)" },
      };
    },
    indicatorSeparator: (base, state) => ({
      ...base,
      display: "none",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      svg: { color: "#0a2540" },
    }),
    container: (base, { selectProps: { width, height } }) => ({
      ...base,
      height: "57px",
    }),
    control: (base, state) => ({
      ...base,
      border: "solid 1px rgba(227, 232, 238, 0.42)",
      borderRadius: "2px",
      boxShadow: "none",
      "&:hover": { borderColor: "#0052ff" },
      height: "57px",
    }),
    singleValue: (base, state) => ({
      ...base,
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "bold",
      letterSpacing: "0.43px",
    }),
  };
};
