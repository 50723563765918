import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from './Accordion';
import StyleComponent from './StyleComponent';
import CopyComponent from './CopyComponent';
import ButtonComponent from './ButtonComponent';
import ColorsComponent from './ColorsComponent';
import BrandingComponent from './BrandingComponent';
import SizeComponent from './SizeComponent';
import PositionComponent from './PositionComponent';
import AnimationComponent from './AnimationComponent';
import ModalComponent from './ModalComponent';

const MainComponent = () => {
  const [activeAccord, setActiveAccord] = useState(null);

  const { style } = useSelector((state) => state.customizer.customizerData);

  const accordionHandler = (accordNum) => {
    setActiveAccord(activeAccord === accordNum ? null : accordNum);
  };

  return (
    <React.Fragment>
      <div className="customizer-menu-title">Your settings and preferences</div>
      <div className="customizer-menu-item">
        <Accordion
          accordNum={1}
          title="Style"
          tooltipText="Choose the basic banner type"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <StyleComponent />
        </Accordion>
        <Accordion
          accordNum={2}
          title="Copy"
          tooltipText="Adjust title and sub title"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <CopyComponent
          // pages={pages}
          />
        </Accordion>
        <Accordion
          accordNum={3}
          title="Button"
          tooltipText="Adjust the design of the button"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <ButtonComponent />
        </Accordion>
        <Accordion
          accordNum={4}
          title="Colors"
          tooltipText="Choose a color for background and fonts"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <ColorsComponent />
        </Accordion>
        <Accordion
          accordNum={5}
          title="Branding"
          tooltipText="Remove the branding"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <BrandingComponent />
        </Accordion>
        <Accordion
          accordNum={6}
          title="Size"
          tooltipText="Adjust the height of the modal"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <SizeComponent />
        </Accordion>
        <Accordion
          accordNum={7}
          title="Position"
          tooltipText="Choose the position"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <PositionComponent />
        </Accordion>
        <Accordion
          accordNum={8}
          title="Animation"
          tooltipText="Add animations or a glitter effect"
          activeAccord={activeAccord}
          accordionHandler={accordionHandler}
        >
          <AnimationComponent />
        </Accordion>
        {style === 'BOTTOM_BANNER' && (
          <Accordion
            accordNum={9}
            title="Modal"
            tooltipText="Adjust the design of the modal"
            activeAccord={activeAccord}
            accordionHandler={accordionHandler}
          >
            <ModalComponent />
          </Accordion>
        )}
      </div>
    </React.Fragment>
  );
};
// export default memo(MainComponent);
export default MainComponent;
