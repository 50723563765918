import React from 'react';
import { format, differenceInDays } from 'date-fns';
import Select from 'react-select';
import { DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowUp } from '../../assets/arrowup.svg';
import SelectWithDoubleArrows from '../../components/SelectWithDoubleArrows';

export const customStyle = (isStatusSelect) => {
  return {
    option: (base, data) => {
      return {
        ...base,
        backgroundColor: 'white',
        color: '#0a2540',
        fontSize: '14px',
        letterSpacing: '0.43px',
        '&:active': { backgroundColor: 'rgba(227, 232, 238, 0.42)' },
        '&:hover': { backgroundColor: 'rgba(227, 232, 238, 0.42)' },
        height: '57px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: isStatusSelect ? 'bold' : 'normal',
      };
    },
    menu: (base, { selectProps: { width, height } }) => ({
      ...base,
      borderRadius: '2px',
      border: 'solid 1px #f3f5f8',
      backgroundColor: 'white',
      boxShadow: 'none',
    }),
    container: (base, { selectProps: { width, height } }) => ({
      ...base,
      width: '256px',
      height: '57px',
    }),
    control: (base, state) => ({
      ...base,
      border: 'solid 1px rgba(227, 232, 238, 0.42)',
      borderRadius: '1px',
      boxShadow: 'none',
      '&:hover': { borderColor: '#0052ff' },
      height: '57px',
    }),
    singleValue: (base, state) => ({
      ...base,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      letterSpacing: '0.43px',
      fontWeight: isStatusSelect ? 'bold' : 'normal',
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      display: 'none',
    }),
  };
};

export const mapProjectsRows = (
  pages,
  campaigns,
  onCampaignChange,
  onStatusChange,
  onDelete,
  pageId,
  campaignId,
  status,
  userPlan
) => {
  let rows = [];
  let manipulatedPages = JSON.parse(JSON.stringify(pages));

  let campaignsOptions = campaigns.getUserCampaigns.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  let touchedRowPage =
    manipulatedPages && manipulatedPages.length
      ? manipulatedPages.filter((p) => p.id === pageId)
      : null;
  let touchedRowCampaign;

  if (touchedRowPage && touchedRowPage.length) {
    touchedRowCampaign = touchedRowPage[0].campaigns.filter(
      (c) => c.campaignId === campaignId
    );
  }

  if (manipulatedPages && manipulatedPages.length) {
    manipulatedPages
      .sort((a, b) => {
        let aT = new Date(a.id);
        let bT = new Date(b.id);

        return aT - bT;
      })
      .forEach((page, rowIndex) => {
        let row = {};
        let selectedCampaign;
        let selectedCampaignStatus;
        if (page.selectedCampaign) {
          selectedCampaign = campaignsOptions.filter(
            (c) => c.value === page.selectedCampaign
          );

          if (selectedCampaign && selectedCampaign.length) {
            selectedCampaign = selectedCampaign[0];

            selectedCampaignStatus = page.campaigns.filter(
              (c) => c.campaignId === page.selectedCampaign
            );

            if (selectedCampaignStatus && selectedCampaignStatus.length) {
              selectedCampaignStatus = {
                value: selectedCampaignStatus[0].status,
                label: selectedCampaignStatus[0].status,
              };
            } else {
              selectedCampaignStatus = {
                valueL: 'PAUSED',
                label: 'PAUSED',
              };
            }
          }
        }

        let desiredCampaignOnPage =
          pageId === page.id && campaignId
            ? campaignsOptions.filter((c) => c.value === campaignId)[0]
            : null;

        let clicks = 0;
        let impressions = 0;
        let pageFirstAnalyticsDate;

        let pageCampaigns = page.campaigns.filter(
          (c) => c.campaignId === page.selectedCampaign
        );

        if (pageCampaigns && pageCampaigns.length && pageCampaigns.length > 0) {
          pageCampaigns[0].campaign.analytics
            .filter(({ pageId }) => pageId === page.id)
            .forEach((e, i) => {
              if (i === 0) {
                pageFirstAnalyticsDate = e.createdAt;
              }
              clicks += e.clicks;

              let isWithin30Days =
                differenceInDays(Date.now(), new Date(e.createdAt)) <= 30
                  ? true
                  : false;

              impressions += isWithin30Days ? e.impressions : 0;
            });
        }

        if (userPlan) {
          if (impressions >= userPlan.getUserPlan.plan.impressions) {
            impressions = userPlan.getUserPlan.plan.impressions;
          }
        }

        row.key = rowIndex;
        row.url = (
          <span
            className="tabel-project-cell"
            onClick={(e) => {
              window.open(
                `http://${page.pageUrl}`,
                '_blank',
                'width=900,height=600'
              );
            }}
          >
            {page.pageUrl}
            <ArrowUp />
          </span>
        );
        row.campaign = (
          // <Select
          //   id={rowIndex}
          //   options={campaignsOptions}
          //   onChange={async (e) => {
          //     if (e.value === page.selectedCampaign) {
          //       return;
          //       }
          //     await onCampaignChange(page.id, e.value, userPlan);
          //   }}
          //   value={selectedCampaign ? selectedCampaign : desiredCampaignOnPage}
          //   styles={customStyle()}
          //   isSearchable={false}
          // />
          <SelectWithDoubleArrows
            type="campaign"
            activeMenuItem={
              selectedCampaign ? selectedCampaign : desiredCampaignOnPage
            }
            menuItems={campaignsOptions}
            callback={async (val) => {
              if (val === page.selectedCampaign) return;
              await onCampaignChange(page.id, val, userPlan);
            }}
          />
        );
        row.impressions = impressions;
        row.clicks = clicks;
        row.active_since = format(
          new Date(page.updatedAt).getTime(),
          'd. MMM YYY'
        );

        row.status = (
          // <Select
          //   isSearchable={false}
          //   id={`${rowIndex}-status`}
          //   styles={customStyle(true)}
          //   options={[
          //     { value: 'ACTIVE', label: 'ACTIVE' },
          //     { value: 'PAUSED', label: 'PAUSED' },
          //     { value: 'DELETE', label: 'DELETE' },
          //   ]}
          //   formatOptionLabel={(value) => {
          //     return (
          //       <div style={{ display: 'flex', alignItems: 'center' }}>
          //         {value.label === 'DELETE' && (
          //           <DeleteOutlined
          //             style={{
          //               width: '9px',
          //               height: '9px',
          //               color: '#0a2540',
          //               marginRight: '10px',
          //               marginLeft: '17px',
          //               marginBottom: '4px',
          //             }}
          //           />
          //         )}
          //         {value.label !== 'DELETE' && (
          //           <span
          //             style={{
          //               width: '9px',
          //               height: '9px',
          //               margin: '6px 11px 6px 17px',
          //               backgroundColor:
          //                 value.label === 'ACTIVE' ? '#1dbc37' : '#f28e39',
          //               borderRadius: '10px',
          //             }}
          //           ></span>
          //         )}
          //         {value.label}
          //       </div>
          //     );
          //   }}
          //   onChange={async (e) => {
          //     if (e.value === selectedCampaignStatus.value) {
          //       return;
          //     }
          //     await onStatusChange(
          //       page.id,
          //       e.label,
          //       page.selectedCampaign,
          //       userPlan
          //     );
          //   }}
          //   value={
          //     selectedCampaignStatus
          //       ? selectedCampaignStatus
          //       : {
          //           value: 'PAUSED',
          //           label: 'PAUSED',
          //         }
          //   }
          // />
          <SelectWithDoubleArrows
            type='status'
            activeMenuItem={
              selectedCampaignStatus
                ? selectedCampaignStatus
                : {
                    value: 'PAUSED',
                    label: 'PAUSED',
                  }
            }
            menuItems={[
              { value: 'ACTIVE', label: 'ACTIVE' },
              { value: 'PAUSED', label: 'PAUSED' },
              { value: 'DELETE', label: 'DELETE' },
            ]}
            callback={async (val) => {
              if (val === selectedCampaignStatus.value) return;

              await onStatusChange(
                page.id,
                val,
                page.selectedCampaign,
                userPlan
              );
            }}
          />
        );
        rows.push(row);
      });
  }
  return rows;
};

export const getActiveCampaigns = (userPagesObjectArray, campaigns) => {
  let results = 0;
  let activeCampaigns = 0;

  if (
    userPagesObjectArray &&
    userPagesObjectArray.length &&
    campaigns &&
    campaigns.getUserCampaigns
  ) {
    userPagesObjectArray.forEach((page) => {
      if (page && page.campaigns) {
        page.campaigns.forEach((c) => {
          if (c.status === 'ACTIVE') {
            activeCampaigns += 1;
          }
        });
      }
    });

    results = (activeCampaigns / userPagesObjectArray.length) * 100;
  }

  return results.toFixed(0);
};

export const getClicksCount = (userPagesObjectArray) => {
  let now = Date.now();

  let weekClicks = 0;
  let weekImpressions = 0;

  let prevWeekClicks = 0;
  let prevWeekImpressions = 0;
  if (userPagesObjectArray && userPagesObjectArray.length > 0) {
    userPagesObjectArray.forEach((page) => {
      if (page && page.campaigns) {
        page.campaigns.forEach((campaigns) => {
          let { campaign, pageId } = campaigns;
          let { analytics } = campaign;

          analytics
            .filter(({ pageId }) => pageId === page.id)
            .forEach(({ clicks, impressions, createdAt, pageId }) => {
              let isWithin7Days =
                differenceInDays(now, new Date(createdAt)) <= 7 ? true : false;
              if (isWithin7Days) {
                weekClicks += clicks;
                weekImpressions += impressions;
              } else {
                prevWeekClicks += clicks;
                prevWeekImpressions += impressions;
              }
            });
        });
      }
    });
  }

  let clicksGrowth =
    ((weekClicks - prevWeekClicks) /
      (prevWeekClicks === 0 ? 1 : prevWeekClicks)) *
    100;
  let impressionsGrowth =
    ((weekImpressions - prevWeekImpressions) /
      (prevWeekImpressions === 0 ? 1 : prevWeekImpressions)) *
    100;

  return {
    weekClicks,
    weekImpressions,
    clicksGrowth:
      typeof clicksGrowth === 'number' ? clicksGrowth.toFixed(0) : clicksGrowth,
    impressionsGrowth:
      typeof impressionsGrowth === 'number'
        ? impressionsGrowth.toFixed(0)
        : impressionsGrowth,
  };
};

export const getUserActivePagesCount = (data) => {
  let count = 0;

  if (data && data.length > 0) {
    data.forEach((page) => {
      if (page.campaigns) {
        page.campaigns.forEach((campaign) => {
          if (campaign.status === 'ACTIVE') {
            count++;
          }
        });
      }
    });
  }

  return count;
};
