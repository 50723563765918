import React from 'react';

const MobileIcon = ({ switcher }) => {
  return (
    <svg viewBox="0 0 480 480" style={{ width: '28px', height: '19px' }}>
      <g>
        <g>
          <path
            d="M352,0H128c-22.08,0.026-39.974,17.92-40,40v400c0.026,22.08,17.92,39.974,40,40h224c22.08-0.026,39.974-17.92,40-40V40
			C391.974,17.92,374.08,0.026,352,0z M376,440c0,13.255-10.745,24-24,24H128c-13.255,0-24-10.745-24-24v-40h272V440z M376,384H104
			V64h272V384z M376,48H104v-8c0-13.255,10.745-24,24-24h224c13.255,0,24,10.745,24,24V48z"
            fill={switcher ? '#0052ff' : 'rgba(10, 37, 64, 0.12)'}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M240,408c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S253.255,408,240,408z M240,440c-4.418,0-8-3.582-8-8
			s3.582-8,8-8s8,3.582,8,8S244.418,440,240,440z"
            fill={switcher ? '#0052ff' : 'rgba(10, 37, 64, 0.12)'}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M248,24h-16c-4.418,0-8,3.582-8,8s3.582,8,8,8h16c4.418,0,8-3.582,8-8S252.418,24,248,24z"
            fill={switcher ? '#0052ff' : 'rgba(10, 37, 64, 0.12)'}
          />
        </g>
      </g>
    </svg>
  );
};
export default MobileIcon;
