import React from 'react';
import CustomSelect from './CustomSelect';
import { SIZE_OPTIONS } from '../../../layout/constants';
import { useSelector } from 'react-redux';
import { addSize } from '../../../redux/customizerSidebarSlice';

const SizeComponent = () => {
  const { size } = useSelector((state) => state.customizer.customizerData);

  const mappedSizeObject = SIZE_OPTIONS.filter((f) => f.value === size);

  return (
    <>
      <CustomSelect
        label="Height"
        activeMenuItem={mappedSizeObject[0]}
        menuItems={SIZE_OPTIONS}
        actionFunc={addSize}
      />
    </>
  );
};
export default SizeComponent;
