import React from 'react';
import { Slider } from 'antd';
import CustomSelect from './CustomSelect';
import {
  MODAL_STYLE_OPTIONS,
  MODAL_ALIGNMENT,
} from '../../../layout/constants';
import { ChromePicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import {
  addModalStyle,
  addModalAlignment,
  addModalBorderRadius,
  addModalShadow,
} from '../../../redux/customizerSidebarSlice';
import UploadImageButton from './UploadImageButton';

const ModalComponent = () => {
  const dispatch = useDispatch();
  const {
    modalStyle,
    modalAlignment,
    modalBorderRadius,
    modalShadow,
  } = useSelector((state) => state.customizer.customizerData);

  const customizerData = useSelector(
    (state) => state.customizer.customizerData
  );

  let mappedStyleObject = MODAL_STYLE_OPTIONS.filter(
    (f) => f.value === modalStyle
  );
  let mappedModalAlignment = MODAL_ALIGNMENT.filter(
    (f) => f.value === modalAlignment
  );

  return (
    <>
      <div className="customizer-menu-free">
        <CustomSelect
          label="Style"
          activeMenuItem={mappedStyleObject[0]}
          menuItems={MODAL_STYLE_OPTIONS}
          actionFunc={addModalStyle}
        />

        {modalStyle === 'WITH_IMAGE' && (
          <div className="f-item">
            <div className="tit">Image</div>
            <UploadImageButton />
          </div>
        )}

        <CustomSelect
          label="Alignement"
          activeMenuItem={mappedModalAlignment[0]}
          menuItems={MODAL_ALIGNMENT}
          actionFunc={addModalAlignment}
        />

        <div className="f-item">
          <div className="tit">Border Radius</div>
          <Slider
            defaultValue={modalBorderRadius}
            // disabled={
            //     buttonStyle !== BUTTON_STYLE[0].value &&
            //     buttonStyle !== BUTTON_STYLE[1].value
            // }
            onChange={(value) => {
              dispatch(addModalBorderRadius(value));
            }}
          />
        </div>

        <div className="f-item">
          <div className="tit">Shadow</div>
          <ChromePicker
            color={modalShadow}
            onChange={(e) => {
              dispatch(addModalShadow(e.hex));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
