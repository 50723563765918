import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchUserCampaign,
  // fetchUserCampaignData,
  // hidePublishButton,
} from '../../redux/customizerSidebarSlice';
import GoBack from '../GoBack';
import MainComponent from './CustomizerSidebarComponents/MainComponent';
// import { message } from 'antd';
// import { useUpdateCampaignCustomizer } from '../../bannernote/customizer/useMutations'; //
// import Button from '../../form/components/Button';
import { useUserCampaignsServer } from '../../bannernote/customizer/useQueries';
// import { browserHistory } from '../../browserHistory';

const CustomizerSidebar = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const { data } = useUserCampaignsServer();

  useEffect(() => {
    if (data) {
      dispatch(fetchUserCampaign(data));
    }
  }, [data]);

  // const [updateCustomizer] = useUpdateCampaignCustomizer();

  // const { isActiveBtn, activeCampaign, customizerData } = useSelector(
  //   (state) => state.customizer
  // );

  return (
    <div className="customizer-sidebar-wrapper">
      <div className="go-back">
        <GoBack
          onClickCB={() => {
            history.push('/');
          }}
        />
      </div>
      <div className="customizer-menu">
        <MainComponent />

        {/* <div className="f-item" style={{ margin: '20px auto 0' }}>
          {isActiveBtn && (
            <Button
              children="Publish Changes"
              className="wf-btn-primary__sidebar-customizer"
              onClick={async () => {
                try {
                  const results = await updateCustomizer({
                    variables: {
                      ...customizerData,
                      campaignId: activeCampaign,
                    },
                  });
                  if (results.data && results.data.updateCampaignCustomizer) {
                    message.success('Successfully saved.');
                  } else {
                    message.warn(
                      'Unable to save the change, please try again later'
                    );
                  }
                } catch (error) {
                  message.error(
                    'An error occured while trying to save the changes!'
                  );
                }
              }}
            />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default CustomizerSidebar;
