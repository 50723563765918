import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './custom_select.module.scss';
import ArrowIcon from '../../../ArrowIcon';
import useOnClickOutside from '../../../../hooks/useClickOutside';
import { useDispatch } from 'react-redux';

const CustomSelect = ({
  label,
  isDisabled = false,
  activeMenuItem,
  menuItems,
  actionFunc,
}) => {
  const dispatch = useDispatch();
  const menuRef = useRef();
 
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const openMenuHandler = () => {
    setIsOpenMenu(!isOpenMenu);
  };
  const closeMenuHandler = () => {
    setIsOpenMenu(false);
  };
  const selectHandler = (itemValue) => {
    closeMenuHandler();
    dispatch(actionFunc(itemValue))
  };

  useOnClickOutside(menuRef, closeMenuHandler);

  return (
    <div className={styles.customSelesctContainer}>
      <label className={styles.labelSelect}>{label}</label>
      <div
        ref={menuRef}
        className={classNames(
          styles.customSelesctBlock,
          isDisabled && styles.disableSelectBlock
        )}
      >
        <input
          className={classNames(
            styles.customSelect,
            isOpenMenu && !isDisabled && styles.openedState,
            isDisabled && styles.disabledInput
          )}
          value={isOpenMenu && !isDisabled ? 'CHOOSE' : activeMenuItem.label}
          onClick={openMenuHandler}
          placeholder={isOpenMenu && !isDisabled ? 'CHOOSE' : activeMenuItem.label}
          readOnly
        />
        <div className={styles.selectArrowContainer}>
          <ArrowIcon
            classIcon={classNames(
              styles.arrowIcon,
              isOpenMenu && !isDisabled && styles.rotateArrow
            )}
            switcher={isOpenMenu && !isDisabled}
          />
        </div>
        <ul
          className={classNames(
            styles.selectMenu,
            isOpenMenu && !isDisabled && styles.openedMenu
          )}
        >
          {menuItems.map((item, idx) => {
            return (
              <li
                key={`${item.value} + ${idx}`}
                className={classNames(
                  styles.selectMenuItem,
                  activeMenuItem.value === item.value && styles.activeItemMenu
                  // activeItem.label.length > 0
                  //   ? activeItem.label === item.label && styles.activeItemMenu
                  //   : activeMenuItem === item.value && styles.activeItemMenu
                )}
                data-item={item.value}
                onClick={() => selectHandler(item.value)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default CustomSelect;
