import React, { useState, useEffect } from 'react';
import { Input as AntInput, message } from 'antd';
// import {
//   useCustomizerMutationClient,
//   useUpdateCampaignCustomizer,
// } from '../../../bannernote/customizer/useMutations';
import {
  FONTS,
  FONTS_STYLES,
  FONTS_SIZE,
  MODAL_ALIGNMENT,
} from '../../../layout/constants';
import CustomSelect from './CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import {
  addBannerText,
  addFontFamily,
  addFontWeight,
  addFontSize,
  addTitleTextAlign,
  addSubtitleText,
  addSubtitleFontFamily,
  addSubtitleFontWeight,
  addSubtitleFontSize,
  addSubtitleTextAlign,
} from '../../../redux/customizerSidebarSlice';

let sharedImojiBtnStyle = {
  width: '44px',
  height: '42px',
  fontSize: '14px',
  lineHeight: '1.43',
  padding: '10px 12px 12px 13px',
  borderRadius: '2px',
  border: 'solid 1px rgba(227,232,238, 0.42)',
  backgroundColor: '#ffffff',
  outline: 0,
  marginRight: '10px',
  cursor: 'pointer',
};

const CopyComponent = ({
  // bannerVisible,
  // setPrevPosition,
  // customizerData,
  // setCopyChangesSaved,
  setBtnActive,
  // campaignId = 2,
}) => {
  // const [localBannerText, setLocalBannerText] = useState(null);
  // const [btnActive, setBtnActive] = useState(false);
  // const [updateCustomizerClient] = useCustomizerMutationClient();
  // const [updateCustomizer] = useUpdateCampaignCustomizer();
  const dispatch = useDispatch();
  const {
    bannerText,
    fontFamily,
    fontWeight,
    fontSize,
    style,
    titleTextAlign,
    subtitleText,
    subtitleFontFamily,
    subtitleFontWeight,
    subtitleFontSize,
    subtitleTextAlign,
  } = useSelector((state) => state.customizer.customizerData);

  const customizerData = useSelector(
    (state) => state.customizer.customizerData
  );
  
  // let {
  //   bannerText,
  //   fontFamily,
  //   fontWeight,
  //   fontSize,
  //   activeCampaign,
  //   titleTextAlign,
  //   subtitleText,
  //   subtitleFontFamily,
  //   subtitleFontWeight,
  //   subtitleFontSize,
  //   subtitleTextAlign,
  //   style,
  // } =
  //   customizerData && customizerData.customizer
  //     ? customizerData.customizer
  //     : {
  //         bannerText: '',
  //         fontFamily: '',
  //         fontWeight: '',
  //         fontSize: '',
  //         titleTextAlign: '',
  //         subtitleText: '',
  //         subtitleFontFamily: '',
  //         subtitleFontWeight: '',
  //         subtitleFontSize: '',
  //         subtitleTextAlign: '',
  //         style: '',
  //       };

  // fontSize = fontSize || 12;
  let mappedFontObject = FONTS.filter((f) => f.value === fontFamily);
  let mappedFontWeightObject = FONTS_STYLES.filter(
    (f) => f.value === fontWeight
  );
  let mappedFontSizeObject = FONTS_SIZE.filter((f) => f.value === fontSize);
  let mappedTitleTextAlignObject = MODAL_ALIGNMENT.filter(
    (f) => f.value === titleTextAlign
  );
  let mappedSubtitleFontObject = FONTS.filter(
    (f) => f.value === subtitleFontFamily
  );
  let mappedSubtitleFontWeightObject = FONTS_STYLES.filter(
    (f) => f.value === subtitleFontWeight
  );
  let mappedSubtitleFontSizeObject = FONTS_SIZE.filter(
    (f) => f.value === subtitleFontSize
  );
  let mappedSubtitleTextAlignObject = MODAL_ALIGNMENT.filter(
    (f) => f.value === subtitleTextAlign
  );

  return (
    <div className="customizer-menu-free">
      <div className="f-item">
        <div className="tit">Banner Text</div>
        <AntInput.TextArea
          placeholder="container"
          value={bannerText}
          onChange={(e) => {
            dispatch(addBannerText(e.target.value));
          }}
          cols={8}
          rows={3}
        />
        <div className="btns" style={{ marginTop: '10px' }}>
          <button
            style={{
              ...sharedImojiBtnStyle,
            }}
            onClick={() => {
              dispatch(addBannerText(bannerText + '🎉'));
            }}
          >
            🎉
          </button>
          <button
            style={{
              ...sharedImojiBtnStyle,
            }}
            onClick={() => {
              dispatch(addBannerText(bannerText + '🎊'));
            }}
          >
            🎊
          </button>
          <button
            style={{
              ...sharedImojiBtnStyle,
            }}
            onClick={() => {
              dispatch(addBannerText(bannerText + '🔥'));
            }}
          >
            🔥
          </button>
          <button
            style={{
              ...sharedImojiBtnStyle,
              width: 'fit-content',
              marginRight: '0px',
            }}
            onClick={() => {
              window.open('https://getemoji.com/', '_blank');
            }}
          >
            more
          </button>
        </div>
      </div>

      <CustomSelect
        label="Font Family"
        activeMenuItem={mappedFontObject[0]}
        menuItems={FONTS}
        actionFunc={addFontFamily}
      />
      <CustomSelect
        label="Font Weight"
        activeMenuItem={mappedFontWeightObject[0]}
        menuItems={FONTS_STYLES}
        actionFunc={addFontWeight}
      />
      <CustomSelect
        label="Font Size"
        activeMenuItem={mappedFontSizeObject[0]}
        menuItems={FONTS_SIZE}
        actionFunc={addFontSize}
      />

      {style === 'BOTTOM_BANNER' && (
        <div>
          <CustomSelect
            label="Title Text Align"
            activeMenuItem={mappedTitleTextAlignObject[0]}
            menuItems={MODAL_ALIGNMENT}
            actionFunc={addTitleTextAlign}
          />
          <div className="f-item">
            <div className="tit">Subtitile Text</div>
            <AntInput.TextArea
              placeholder="container"
              value={subtitleText}
              onChange={(e) => {
                dispatch(addSubtitleText(e.target.value));
              }}
              cols={8}
              rows={3}
            />
            <div className="btns" style={{ marginTop: '10px' }}>
              <button
                style={{
                  ...sharedImojiBtnStyle,
                }}
                onClick={() => {
                  dispatch(addSubtitleText(subtitleText + '🎉'));
                }}
              >
                🎉
              </button>
              <button
                style={{
                  ...sharedImojiBtnStyle,
                }}
                onClick={(e) => {
                  dispatch(addSubtitleText(subtitleText + '🎊'));
                }}
              >
                🎊
              </button>
              <button
                style={{
                  ...sharedImojiBtnStyle,
                }}
                onClick={(e) => {
                  dispatch(addSubtitleText(subtitleText + '🔥'));
                }}
              >
                🔥
              </button>
              <button
                style={{
                  ...sharedImojiBtnStyle,
                  width: 'fit-content',
                  marginRight: '0px',
                }}
                onClick={(e) => {
                  window.open('https://getemoji.com/', '_blank');
                }}
              >
                more
              </button>
            </div>
          </div>
          <CustomSelect
            label="Subtitle Font Family"
            activeMenuItem={mappedSubtitleFontObject[0]}
            menuItems={FONTS}
            actionFunc={addSubtitleFontFamily}
          />
          <CustomSelect
            label="Subtitle Font Weight"
            activeMenuItem={mappedSubtitleFontWeightObject[0]}
            menuItems={FONTS_STYLES}
            actionFunc={addSubtitleFontWeight}
          />
          <CustomSelect
            label="Subtitle Font Size"
            activeMenuItem={mappedSubtitleFontSizeObject[0]}
            menuItems={FONTS_SIZE}
            actionFunc={addSubtitleFontSize}
          />
          <CustomSelect
            label="Subtitle Text Alignt"
            activeMenuItem={mappedSubtitleTextAlignObject[0]}
            menuItems={MODAL_ALIGNMENT}
            actionFunc={addSubtitleTextAlign}
          />
        </div>
      )}
      
    </div>
  );
};
export default CopyComponent;
