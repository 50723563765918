import { useMutation } from '@apollo/react-hooks';
import {
    DELETE_PAGE,
    REFETCH_PAGE,
} from './gql';

export const useRefetchPage = () => useMutation(REFETCH_PAGE);
export const useDeletePage = () =>
    useMutation(DELETE_PAGE, {
        refetchQueries: ['userPages'],
    });
