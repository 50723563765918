import gql from 'graphql-tag';

export const userPagesQuery = gql`
    query userPages {
        userPages {
            object
        }
    }
`;

export const userCampaigns = gql`
    query getUserCampaigns {
        getUserCampaigns {
            id
            name
            userId
            analytics {
                clicks
                impressions
                campaignId
                id
                updatedAt
            }
        }
    }
`;

export const ADD_SINGLE_PAGE = gql`
    mutation addSinglePage($pageUrl: String!) {
        addSinglePage(pageUrl: $pageUrl)
    }
`;

export const SET_PAGE_CAMPAIGN = gql`
    mutation setPageCampaign($pageId: Int!, $campaignId: Int!) {
        setPageCampaign(pageId: $pageId, campaignId: $campaignId)
    }
`;

export const UPDATE_CAMPAIGN_ON_PAGE_STATUS = gql`
    mutation updateCampaignOnPageStatus(
        $pageId: Int!
        $campaignId: Int!
        $status: CampaignStatus!
    ) {
        updateCampaignOnPageStatus(pageId: $pageId, campaignId: $campaignId, status: $status)
    }
`;

export const DELETE_PROJECT = gql`
    mutation deleteProject {
        deleteProject
    }
`;

export const SET_PAGE_SELECTED_CAMPAIGN = gql`
    mutation setPageSelectedCampaign($pageId: Int!, $campaignId: Int!) {
        setPageSelectedCampaign(pageId: $pageId, campaignId: $campaignId)
    }
`;
