import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from './layout/Layout';
import Sidebar from './layout/Sidebar';
import Content from './layout/Content';
import Routes from './routes';
import { auth } from './signupLogin/auth';
import { useMeQuery } from './rootUseQuery';
import { useCustomizerQueryClient } from './bannernote/customizer/useQueries';
import { browserHistory } from './browserHistory';
import { useCustomizerMutationClient } from './bannernote/customizer/useMutations';
const token = auth.getAccessToken();

const App = () => {
  const { loading, data, error } = useMeQuery();
  const {
    data: customizerData,
    loading: customizerLoading,
  } = useCustomizerQueryClient();
 
  const [updateCustomizerClient] = useCustomizerMutationClient();
  const [bannerVisible, setBannerVisible] = useState(false);

  async function updateCustomizerData(isOpen, allData) {
    await updateCustomizerClient({ variables: { ...allData, isOpen } });
  }
  const location = useLocation().pathname;

  useEffect(() => {
    if (
      !customizerLoading &&
      browserHistory.location.pathname.includes('/customizer') &&
      customizerData &&
      customizerData.customizer &&
      customizerData.customizer.isOpen === false
    ) {
      updateCustomizerData(true, customizerData.customizer);
    }
  }, []);

  const isNew = data && data.me ? data.me.isNew : false;
  const apiKey = data && data.me ? data.me.apiKey : '';
  const userPages = data && data.me ? data.me.pages : null;

  const isOpenCustomizer =
    customizerData && customizerData.customizer
      ? customizerData.customizer.isOpen
      : false;

  // stupid hack!
  const userRole = data && data.me ? data.me.role : 'ADMIN';

  // const subscriptionCycle = data && data.me ? data.me.subscriptionCycle : '';
  // const numberOfPages = data && data.me ? data.me.pages : 0;

  if (token) {
    return (
      <>
        {/* <Banner
                    setBannerVisible={setBannerVisible}
                    bannerVisible={bannerVisible}
                    subscriptionCycle={subscriptionCycle}
                    numberOfPages={numberOfPages}
                /> */}
        <Layout
          style={{
            marginLeft: location === '/customizer' ? '323px' : '205px',
            backgroundColor: 'rgba(247, 250, 252, 0.5)',
          }}
        >
          <Sidebar
            userRole={userRole}
            isOpenCustomizer={isOpenCustomizer}
            bannerVisible={bannerVisible}
          />
          <Content className="app-page-wrapper" id="app-page-wrapper-id">
            <Routes
              userRole={userRole}
              isNew={isNew}
              isOpenCustomizer={isOpenCustomizer}
              apiKey={apiKey}
              userPages={userPages}
            />
          </Content>
        </Layout>
      </>
    );
  } else {
    return <Routes />;
  }
};

export default App;
