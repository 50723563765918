import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import Home from './components/Home';
import PageNotFound from './components/PageNotFound';
import PageLayout from './components/PageLayout';
import AuthPageLayout from './components/AuthPageLayout';
import Login from './signupLogin/login/components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Signup from './signupLogin/signup/components/Signup';
import { ROLES } from './signupLogin/constants';
// import { auth } from './signupLogin/auth';
// import UserProfile from './userProfile/profile/components/UserProfile';
import ResetPassword from './signupLogin/resetPassword/components/ResetPassword';
import Projects from './bannernote/projects/components/Projects';
import Customizer from './bannernote/customizer/components/Customizer';
import Settings from './userProfile/profile/components/Settings';

export const ROUTE_PATHS = {
  home: '/',
  notFound: '*',
  app: {
    // onboarding: '/onboarding',
    customizer: '/customizer',
    settings: '/settings',
  },
  auth: {
    me: '/me', // TODO add
    signup: '/signup',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password', // TODO add
    // passwordReset: '/password-reset/:token', // TODO add
    // accountSettings: '/account-settings',
  },
};

const Routes = ({ userRole, isNew, isOpenCustomizer, apiKey, userPages }) => {
  return (
    <Switch>
      <ProtectedRoute
        path={ROUTE_PATHS.home}
        exact
        component={(matchProps) => (
          <PageLayout
            Component={Projects}
            {...matchProps}
            title="Projects"
            isOpenCustomizer={isOpenCustomizer}
          />
        )}
        roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.DEVELOPER]}
        userRole={userRole}
      />
      <ProtectedRoute
        path={ROUTE_PATHS.app.customizer}
        exact
        component={(matchProps) => (
          <PageLayout
            Component={Customizer}
            {...matchProps}
            title=""
            isOpenCustomizer={isOpenCustomizer}
            isNew={isNew}
            apiKey={apiKey}
            userPages={userPages}
          />
        )}
        roles={[ROLES.ADMIN, ROLES.DEVELOPER]}
        userRole={userRole}
      />

      <ProtectedRoute
        path={ROUTE_PATHS.app.settings}
        exact
        component={(matchProps) => (
          <PageLayout
            Component={Settings}
            {...matchProps}
            title=""
            isNew={isNew}
            isOpenCustomizer={isOpenCustomizer}
            userRole={userRole}
          />
        )}
        roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.DEVELOPER]}
        userRole={userRole}
      />

      <Route
        path={ROUTE_PATHS.auth.signup}
        exact
        render={(matchProps) => (
          <AuthPageLayout
            Component={Signup}
            title="Sign Up to Bannernote"
            subTitle="Create an account for free without any risks."
            headerLink={{
              title: 'Log in',
              to: ROUTE_PATHS.auth.login,
            }}
            {...matchProps}
          />
        )}
      />

      <Route
        path={ROUTE_PATHS.auth.login}
        exact
        render={(matchProps) => (
          <AuthPageLayout
            Component={Login}
            title="Login to Bannernote"
            subTitle="Welcome back. Login to continue building."
            headerLink={{
              title: 'New? Sign up',
              to: ROUTE_PATHS.auth.signup,
            }}
            {...matchProps}
          />
        )}
      />

      <Route
        path={ROUTE_PATHS.auth.resetPassword}
        exact
        render={(matchProps) => (
          <AuthPageLayout
            Component={ResetPassword}
            title="Reset your password"
            {...matchProps}
          />
        )}
      />

      <Route path={ROUTE_PATHS.notFound} exact component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
