import React from 'react';
import { useField, Field } from 'formik';
import classNames from 'classnames';

const Checkbox = ({ label, linkLabel, iconType, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <div className="checkbox-field-wrapper">
      <div className="checkbox-input">
        <input
          type="checkbox"
          {...field}
          {...props}
          className={classNames({ required: meta.touched && meta.error })}
        />
      </div>
      {label && <div className="form-label">{label}</div>}
      {linkLabel && linkLabel}
    </div>
  );
};

export default Checkbox;
