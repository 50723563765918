import React from 'react';
import { ChromePicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import {
  addBackgroundColor,
  addFontColor,
} from '../../../redux/customizerSidebarSlice';

const ColorsComponent = () => {
  const dispatch = useDispatch();
  const { backgroundColor, fontColor } = useSelector(
    (state) => state.customizer.customizerData
  );

  return (
    <>
      <div className="f-item">
        <div className="tit">Background </div>
        <ChromePicker
          color={backgroundColor}
          onChange={(e) => {
            dispatch(addBackgroundColor(e.hex));
          }}
        />
      </div>

      <div className="f-item">
        <div className="tit">Font</div>
        <ChromePicker
          color={fontColor}
          onChange={(e) => {
            dispatch(addFontColor(e.hex));
          }}
        />
      </div>
    </>
  );
};
export default ColorsComponent;
