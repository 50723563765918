import React, { useEffect, useState, memo } from 'react';
import { Popover, message } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import {
  useCustomizerQueryClient,
  useUserCampaignsServer,
} from '../useQueries';
import Button from '../../../form/components/Button';
import Popup from '../../../components/Popup';
import WelcomePopup from './WelcomePopup';
import { useUpdateUserMutation } from '../../../user/useMutations';
import { useUpdateCampaignCustomizer } from '../useMutations';
import {
  BUTTON_STYLE,
  CLOSE_BTN,
  OPENING_STYLE,
  SIZE_OPTIONS,
} from '../../../layout/constants';
import StartIntegrationPopup from './StartIntegrationPopup';
import CampaignPopup from './CampaignPopup';
import Upgrade, { Step3 } from '../../../upgrade/components/Upgrade';
import { ReactComponent as DownArrow } from '../../../assets/arrow-down-sign-to-navigate.svg';
import { glitter } from '../utils';
import DesktopIcon from '../../../components/DesktopIcon';
import MobileIcon from '../../../components/MobileIcon';
import { useSelector, useDispatch } from 'react-redux';
import { changeCampaign } from '../../../redux/customizerSidebarSlice';
import {
  hidePublishButton,
  setLoadingCustomizer,
  hideLoadingCustomizer,
} from '../../../redux/customizerSidebarSlice';

import 'animate.css';

const content = (
  setShowCampaignsPopup,
  userCampaignsData,
  setVisible,
  activeCampaignName
) => {
  // const [updateCustomizerClient] = useCustomizerMutationClient();
  const dispatch = useDispatch();
  return (
    <div className="popover-dropdown-menu">
      {
        // userCampaignsData &&
        // userCampaignsData.getUserCampaigns &&
        // userCampaignsData.getUserCampaigns.length &&
        // userCampaignsData.getUserCampaigns.map((c, index) => {
        userCampaignsData.length &&
          userCampaignsData.map((c, index) => {
            return (
              <div
                className={classNames(
                  'popover-dropdown-menu__item',
                  activeCampaignName === c.id &&
                    'popover-dropdown-menu__item_active'
                )}
                onClick={() => {
                  dispatch(changeCampaign(c));
                  setVisible(false);
                }}
                key={index}
              >
                <span>{c.name}</span>
              </div>
            );
          })
      }

      <div
        style={{ marginTop: '24px', marginBottom: '13px', cursor: 'pointer' }}
      >
        <Button
          children="New Campaign"
          className="wf-btn-primary"
          onClick={(e) => {
            setVisible(false);
            setShowCampaignsPopup(true);
          }}
        />
      </div>
    </div>
  );
};

let cName = '';
let viewModeTemp = 'DESKTOP';
// let animationStyleTemp = '';
// let animationDelayTemp = '';
// let animationEffectTemp = '';

const Customizer = ({ isNew, apiKey, userPages }) => {
  let [visible, setVisible] = useState(false);

  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showCampaignsPopup, setShowCampaignsPopup] = useState(false);
  const [activeCampaignName, setActiveCampaignName] = useState(null);
  const [showPlanUpgradePopup, setShowPlanUpgradePopup] = useState(false);
  // let [targetPlan, setTargetPlan] = useState(2);
  const [successPopup, showSuccessPopup] = useState(false);
  const [viewMode, setViewMode] = useState(null);
  // let [shouldApplyAnimations, setShouldApplyAnimations] = useState(false);
  // let [shouldApplyBounce, setShouldApplyBounce] = useState(false);
  // const [showBanner, setShowBanner] = useState(false);
  // const [activeBtn, setActiveBtn] = useState('DESKTOP');
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state.user.getUserPlan);

  const [previewImg, setPreviewImg] = useState(null);

  const userCampaignsData = useSelector(
    (state) => state.customizer.userCampaigns
  );

  const customizer = useSelector((state) => state.customizer.customizerData);
  const {
    isLoading,
    isLoadingBanner,
    activeCampaign,
    isActiveBtn,
  } = useSelector((state) => state.customizer);

  // const {
  //   data: userCampaignsData,
  //   loading: campaignsLoading,
  // } = useUserCampaignsServer();

  const [updateCustomizer] = useUpdateCampaignCustomizer();

  // const { loading, data, error } = useCustomizerQueryClient();

  const [updateUser] = useUpdateUserMutation();
  // const [updateCustomizerClient] = useCustomizerMutationClient();

  // async function updateCustomizerData(isOpen) {
  //   await updateCustomizerClient({ variables: { ...data.customizer, isOpen } });
  // }

  useEffect(() => {
    let timeout;
    if (isNew) {
      timeout = setTimeout(() => {
        setShowWelcomePopup(true);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  let {
    style,
    bannerText,
    fontFamily,
    fontWeight,
    btnText,
    link,
    openingStyle,
    buttonStyle,
    buttonColor,
    textColor,
    closeButton,
    buttonAlignment,
    buttonPadding,
    backgroundColor,
    titleTextAlign,
    subtitleFontFamily,
    subtitleFontSize,
    subtitleFontWeight,
    subtitleTextAlign,
    subtitleText,
    fontColor,
    branding,
    size,
    borderRadius,
    animationStyle,
    animationDelay,
    animationEffect,
    cadenceInterval,
    attentionSeeker,
    attentionCadence,
    animationType,
    modalStyle,
    modalBorderRadius,
    modalAlignment,
    modalShadow,
    modalImage,
    fontSize,
  } = customizer;

  useEffect(() => {
    let glit;
    if (animationEffect === 'GLITTER') {
      glit = setTimeout(() => {
        glitter();
      }, 2000);
    }
    return () => {
      clearTimeout(glit);
    };
  }, [animationEffect]);

  const animation =
    animationStyle !== 'NONE' && animationType !== 'NONE'
      ? animationType
      : false;
  const attention = attentionSeeker !== 'NONE' ? attentionSeeker : false;

  const animationString = animation ? animation : attention;
  const animationClass = animationString
    ? animationString
        .split('_')
        .map((curr, index) =>
          index === 0
            ? curr.toLowerCase()
            : curr.charAt(0).toUpperCase() + curr.toLowerCase().slice(1)
        )
        .join('')
    : '';

  const campaignExists = userCampaignsData.filter(
    (c) => c.id === activeCampaign
  );

  let campaignName =
    campaignExists && campaignExists.length > 0
      ? campaignExists[0].name
      : cName;
  cName = campaignName;

  if (!viewMode) {
    if (viewModeTemp) {
      setViewMode(viewModeTemp);
      localStorage.setItem('viewmode', viewModeTemp);
    } else {
      let storedViewMode = localStorage.getItem('viewmode');
      let currenViewMode = storedViewMode ? storedViewMode : 'DESKTOP';
      viewModeTemp = currenViewMode;
      setViewMode(viewModeTemp);
      localStorage.setItem('viewmode', viewModeTemp);
    }
  } else {
    viewModeTemp = viewMode;
  }

  // useEffect(() => {
  //   dispatch(setLoadingCustomizer());
  //   setTimeout(() => dispatch(hideLoadingCustomizer()), 200);
  // }, [cadenceInterval, attentionSeeker]);

  let { subscriptionCycle } = userPlan;

  let encodedTextColor = textColor ? textColor.replace('#', '%23') : '%23fff';

  useEffect(() => {
    dispatch(setLoadingCustomizer());
    setTimeout(() => dispatch(hideLoadingCustomizer()), 500);

    let idInt = null;
    // let count = 0;
    if (cadenceInterval > 0) {
      idInt = setInterval(() => {
        const banner = document.querySelector('.banner');
        if (banner) {
          banner.style.animationName = animationClass;
          setTimeout(() => {
            banner.style.animationName = '';
          }, 2000);
        }
      }, cadenceInterval * 1000 + 2000);
      return () => clearInterval(idInt);
    }
  }, [
    cadenceInterval,
    animationStyle,
    animationType,
    animationDelay,
    animationEffect,
    attentionSeeker,
  ]);

  return (
    <div className="customizer-page-wrapper">
      <div className="customizer-popover">
        <div className="rs">
          <div className="rs__btnBlock">
            <div
              className={classNames(
                'desktopRadio',
                viewMode === 'DESKTOP' && 'activeBtn'
              )}
              data-value="DESKTOP"
              onClick={(e) => setViewMode(e.currentTarget.dataset.value)}
            >
              <DesktopIcon switcher={viewMode === 'DESKTOP'} />
            </div>
            <div
              className={classNames(
                'mobileRadio',
                viewMode === 'MOBILE' && 'activeBtn'
              )}
              data-value="MOBILE"
              onClick={(e) => setViewMode(e.currentTarget.dataset.value)}
            >
              <MobileIcon switcher={viewMode === 'MOBILE'} />
            </div>
          </div>
        </div>
        {/* {userPages && userPages.length === 0 && (
          <div className="ring-container">
            <div className="ringring"></div>
            <div className="circle"></div>
          </div>
        )} */}

        <div className="ls">
          {/* <Button
            children={
              userPages && userPages.length === 0
                ? 'Start Integration'
                : 'Integration Guide'
            }
            className="wf-btn-primary"
            onClick={() => {
              setShowUpgradePopup(true);
            }}
          /> */}

          {isActiveBtn && (
            <Button
              children="Publish Changes"
              className="wf-btn-primary__sidebar-customizer"
              onClick={async () => {
                try {
                  const results = await updateCustomizer({
                    variables: {
                      ...customizer,
                      campaignId: activeCampaign,
                    },
                  });
                  if (results.data && results.data.updateCampaignCustomizer) {
                    message.success('Successfully saved.');
                    setTimeout(() => dispatch(hidePublishButton()), 1000);
                  } else {
                    message.warn(
                      'Unable to save the change, please try again later'
                    );
                  }
                } catch (error) {
                  console.log('Error in publish button: ', error);
                  message.error(
                    'An error occured while trying to save the changes!'
                  );
                }
              }}
            />
          )}

          <Popover
            placement="bottomRight"
            content={content(
              setShowCampaignsPopup,
              userCampaignsData,
              setVisible,
              // setActiveCampaignName,
              activeCampaign
            )}
            trigger="click"
            visible={visible}
            onVisibleChange={(visible) => setVisible(visible)}
            className="popover"
          >
            <Button className={visible ? 'active' : ''}>
              {campaignName ? (
                visible ? (
                  <span
                    style={{
                      display: 'flex',
                      marginLeft: '0px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span
                      style={{
                        display: 'block',
                        lineHeight: '19px',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        letterSpacing: '0.5px',
                      }}
                    >
                      {campaignName}
                    </span>
                    <div
                      style={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        marginLeft: '203px',
                        position: 'absolute',
                        top: '49px',
                      }}
                    >
                      <DownArrow
                        className="right-arrow"
                        style={{
                          width: '9px',
                          height: '10px',
                          transform: 'scale(1,-1)',
                        }}
                      />
                      <DownArrow
                        className="right-arrow"
                        style={{
                          width: '9px',
                          height: '10px',
                        }}
                      />
                    </div>
                  </span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      marginLeft: '0px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span
                      style={{
                        display: 'block',
                        lineHeight: '19px',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        letterSpacing: '0.5px',
                      }}
                    >
                      {campaignName}
                    </span>
                    <div
                      style={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        marginLeft: '203px',
                        position: 'absolute',
                        top: '49px',
                      }}
                    >
                      <DownArrow
                        className="right-arrow"
                        style={{
                          width: '9px',
                          height: '10px',
                          transform: 'scale(1,-1)',
                        }}
                      />
                      <DownArrow
                        className="right-arrow"
                        style={{
                          width: '9px',
                          height: '10px',
                        }}
                      />
                    </div>
                  </span>
                )
              ) : visible ? (
                <span
                  style={{
                    display: 'flex',
                    marginLeft: '0px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      lineHeight: '19px',
                      fontFamily: 'Open Sans',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Loading
                  </span>
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      marginLeft: '203px',
                      position: 'absolute',
                      top: '49px',
                    }}
                  >
                    <DownArrow
                      className="right-arrow"
                      style={{
                        width: '9px',
                        height: '10px',
                        transform: 'scale(1,-1)',
                      }}
                    />
                    <DownArrow
                      className="right-arrow"
                      style={{
                        width: '9px',
                        height: '10px',
                      }}
                    />
                  </div>{' '}
                </span>
              ) : (
                <span
                  style={{
                    display: 'flex',
                    marginLeft: '0px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      lineHeight: '19px',
                      fontFamily: 'Open Sans',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Loading
                  </span>
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      marginLeft: '203px',
                      position: 'absolute',
                      top: '49px',
                    }}
                  >
                    <DownArrow
                      className="right-arrow"
                      style={{
                        width: '9px',
                        height: '10px',
                        transform: 'scale(1,-1)',
                      }}
                    />
                    <DownArrow
                      className="right-arrow"
                      style={{
                        width: '9px',
                        height: '10px',
                      }}
                    />
                  </div>{' '}
                </span>
              )}
            </Button>
          </Popover>
        </div>
      </div>

      <div
        className={classNames('window-container', {
          'mobile-view': viewMode === 'MOBILE',
        })}
      >
        <div
          className={classNames('window-row', {
            'mobile-view': viewMode === 'MOBILE',
          })}
        >
          <div className="column left">
            <span className="dot" style={{ background: '#ff7166' }}></span>
            <span className="dot" style={{ background: '#ffbf66' }}></span>
            <span className="dot" style={{ background: '#50c65c' }}></span>
          </div>
        </div>

        <div
          className={classNames('window-content', {
            'window-content-bottom': style === 'BOTTOM_BANNER',
          })}
        >
          {!isLoading && !isLoadingBanner && style === 'TOP_BANNER' ? (
           //{!isLoading && style === 'TOP_BANNER' ? ( 
            <div
              className={classNames('inner-window banner', {
                // glitter: animationEffect !== 'NONE',
                'mobile-view': viewMode === 'MOBILE',
              })}
              style={{
                // animationName: animationClass,
                animationName: cadenceInterval > 0 ? '' : animationClass,
                animationDuration: '2s',
                animationTimingFunction: 'ease',
                animationDelay: animationDelay,
                animationDirectional: 'normal',
                animationFillMode: 'none',
                animationPlayState: 'running',
                animationIterationCount: cadenceInterval > 0 ? 'infinite' : '1',
                backgroundColor: backgroundColor,
                color: fontColor,
                height: '100%',
                justifyContent:
                  viewMode === 'MOBILE'
                    ? branding === 'WITHOUT_BRANDING'
                      ? 'flex-start'
                      : 'flex-start'
                    : 'space-between',
                minHeight:
                  size === SIZE_OPTIONS[0].value
                    ? '44px'
                    : size === SIZE_OPTIONS[1].value
                    ? '55px'
                    : '66px',
              }}
            >
              {animationEffect === 'GLITTER' && <div className="glitter" />}
              <div
                style={{
                  color: fontColor,
                  fontSize: viewMode === 'MOBILE' ? '6.8px' : '10.8px',
                  width: viewMode === 'MOBILE' ? '100%' : 'fit-content',
                  minHeight: '12px',
                }}
                className="branding"
              >
                {branding === 'WITH_BRANDING' ? <>⚡by bannernote</> : <></>}
              </div>
              <div
                style={{
                  ...(viewMode === 'MOBILE' && {
                    display: 'flex',
                    float: 'left',
                    marginTop: '7px',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    textAlign: 'center',
                    alignItems: 'center',
                  }),
                }}
              >
                <span
                  style={{
                    fontSize: `${fontSize}px` || '12px',
                    fontFamily: fontFamily || 'Open Sans',
                    fontWeight: fontWeight || '700',
                    ...(viewMode === 'MOBILE' && {
                      fontSize: `${fontSize}px` || '8.1px',
                      // maxWidth: '225px',
                      display: 'block',
                      marginBottom: '10px',
                    }),
                  }}
                >
                  {bannerText}
                </span>
                <span
                  style={{
                    marginLeft: viewMode === 'MOBILE' ? '0px' : '10px',
                    display: 'inline-flex',
                    marginRight: viewMode === 'MOBILE' ? '7px' : '0px',
                  }}
                >
                  {buttonStyle !== 'no_button' && (
                    <Button
                      children={
                        <>
                          {btnText}
                          {buttonStyle === 'arrow' && (
                            <div id="cta">
                              <span
                                class="arrow primera next bounceAlpha"
                                style={{
                                  marginLeft: '10px',
                                  backgroundImage: `url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8px' height='8px' viewBox='0 0 792.033 792.033' fill='${encodedTextColor}' style='enable-background:new 0 0 792.033 792.033;' xml:space='preserve'> <g><g id='_x38_'><g><path  d='M617.858,370.896L221.513,9.705c-13.006-12.94-34.099-12.94-47.105,0c-13.006,12.939-13.006,33.934,0,46.874l372.447,339.438L174.441,735.454c-13.006,12.94-13.006,33.935,0,46.874s34.099,12.939,47.104,0l396.346-361.191c6.932-6.898,9.904-16.043,9.441-25.087C627.763,386.972,624.792,377.828,617.858,370.896z'/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>")`,
                                }}
                              ></span>
                              <span
                                class="arrow segunda next bounceAlpha"
                                style={{
                                  backgroundImage: `url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8px' height='8px' viewBox='0 0 792.033 792.033' fill='${encodedTextColor}' style='enable-background:new 0 0 792.033 792.033;' xml:space='preserve'> <g><g id='_x38_'><g><path  d='M617.858,370.896L221.513,9.705c-13.006-12.94-34.099-12.94-47.105,0c-13.006,12.939-13.006,33.934,0,46.874l372.447,339.438L174.441,735.454c-13.006,12.94-13.006,33.935,0,46.874s34.099,12.939,47.104,0l396.346-361.191c6.932-6.898,9.904-16.043,9.441-25.087C627.763,386.972,624.792,377.828,617.858,370.896z'/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>")`,
                                }}
                              ></span>
                            </div>
                          )}
                        </>
                      }
                      style={{
                        width: 'fit-content',
                        minHeight: '24px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 12px',
                        borderRadius:
                          borderRadius >= 0 ? `${borderRadius}px` : '3.3px',
                        border:
                          buttonStyle === BUTTON_STYLE[0].value
                            ? `solid 1px ${
                                buttonColor === 'transparent'
                                  ? 'white'
                                  : buttonColor
                              }`
                            : 'transparent',
                        fontSize: `${fontSize}px` || '9.1px',
                        fontWeight: fontWeight || 'bold',
                        textDecoration:
                          buttonStyle === BUTTON_STYLE[3].value
                            ? `underline`
                            : 'none',
                        backgroundColor:
                          buttonStyle !== BUTTON_STYLE[2].value &&
                          buttonStyle !== BUTTON_STYLE[0].value &&
                          buttonStyle !== 'underlined' &&
                          buttonStyle !== 'arrow'
                            ? buttonColor || 'transparent'
                            : 'transparent',
                        outline: 0,
                        cursor: 'pointer',
                        color: textColor,
                      }}
                      onClick={(e) => {
                        if (openingStyle === OPENING_STYLE[0].value) {
                          window.open(link, '_blank');
                        } else {
                          window.location.replace(link);
                        }
                      }}
                    />
                  )}
                </span>
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  ...(viewMode === 'MOBILE' && {
                    position: 'absolute',
                    right: '9px',
                    top: '2px',
                  }),
                }}
              >
                {closeButton === CLOSE_BTN[0].value ? (
                  <svg
                    width={viewMode === 'MOBILE' ? '8px' : '10px'}
                    height={viewMode === 'MOBILE' ? '8px' : '12px'}
                    viewBox="0 0 413.348 413.348"
                    enableBackground="new 0 0 413.348 413.348"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                      id="Stroke-1"
                      fill={fontColor}
                    ></path>
                  </svg>
                ) : (
                  ' '
                )}
              </div>
            </div>
          ) : !isLoading && !isLoadingBanner && style === 'BOTTOM_BANNER' ? (
          // !isLoading && style === 'BOTTOM_BANNER' ? (
            <div
              className={classNames('bottom-banner banner', {
                // glitter: animationEffect !== 'NONE',
                'mobile-view': viewMode === 'MOBILE',
              })}
              style={{
                // animationName: animationClass,
                animationName: cadenceInterval > 0 ? '' : animationClass,
                animationDuration: '2s',
                animationTimingFunction: 'ease',
                animationDelay: animationDelay,
                animationDirectional: 'normal',
                animationFillMode: 'none',
                animationPlayState: 'running',
                animationIterationCount: cadenceInterval > 0 ? 'infinite' : '1',
                // animationIterationCount: '1',
                // animationIterationCount:
                //   attentionCadence === 'INTERVAL' ? cadenceInterval : 'initial',
                backgroundColor: backgroundColor,
                color: fontColor,
                minHeight:
                  size === SIZE_OPTIONS[0].value
                    ? '100px'
                    : size === SIZE_OPTIONS[1].value
                    ? '120px'
                    : '150px',
                borderRadius: `${modalBorderRadius}px`,
                left:
                  modalAlignment == 'LEFT'
                    ? '20px'
                    : modalAlignment == 'CENTER'
                    ? 0
                    : 'initial',
                right:
                  modalAlignment == 'RIGHT'
                    ? '20px'
                    : modalAlignment == 'CENTER'
                    ? 0
                    : 'initial',
                margin: modalAlignment == 'CENTER' ? 'auto' : 'initial',
                boxShadow: `4px 4px ${modalShadow}`,
              }}
            >
              {animationEffect === 'GLITTER' && <div className="glitter" />}
              <div className="top-section">
                <div
                  style={{
                    color: fontColor,
                    fontSize: viewMode === 'MOBILE' ? '6.8px' : '10.8px',
                    width: viewMode === 'MOBILE' ? '100%' : 'fit-content',
                    minHeight: '12px',
                  }}
                  className="branding"
                >
                  {branding === 'WITH_BRANDING' ? <>⚡by bannernote</> : <></>}
                </div>
                <div
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  {closeButton === CLOSE_BTN[0].value ? (
                    <svg
                      width={viewMode === 'MOBILE' ? '8px' : '10px'}
                      height={viewMode === 'MOBILE' ? '8px' : '12px'}
                      viewBox="0 0 413.348 413.348"
                      enableBackground="new 0 0 413.348 413.348"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                        id="Stroke-1"
                        fill={fontColor}
                      ></path>
                    </svg>
                  ) : (
                    ' '
                  )}
                </div>
              </div>
              <div
                style={{
                  display: modalStyle === 'SPLIT' ? 'flex' : 'block',
                  justifyContent: 'space-between',
                }}
              >
                {modalStyle === 'WITH_IMAGE' &&
                modalImage &&
                modalImage.length ? (
                  <div className="image">
                    <img
                      // src={`${process.env.REACT_APP_HTTP_GRAPHQL_URL}/${modalImage}`}
                      src={modalImage}
                      style={{
                        width: '100%',
                        padding: '1rem',
                        height: '200px',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div
                  style={{ width: modalStyle === 'SPLIT' ? '70%' : 'inital' }}
                >
                  <div
                    style={{
                      ...(viewMode === 'MOBILE' && {
                        display: 'flex',
                        float: 'left',
                        marginTop: '7px',
                        width: '100%',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        textAlign: 'center',
                        alignItems: 'center',
                      }),
                      textAlign: titleTextAlign,
                    }}
                  >
                    <span
                      style={{
                        fontSize: `${fontSize}px` || '12px',
                        fontFamily: fontFamily || 'Open Sans',
                        fontWeight: fontWeight || '700',
                        ...(viewMode === 'MOBILE' && {
                          fontSize: `${fontSize}px` || '8.1px',
                          // maxWidth: '225px',
                          display: 'block',
                          marginBottom: '10px',
                        }),
                      }}
                    >
                      {bannerText}
                    </span>
                  </div>
                  <div
                    style={{
                      ...(viewMode === 'MOBILE' && {
                        display: 'flex',
                        float: 'left',
                        marginTop: '7px',
                        width: '100%',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        textAlign: 'center',
                        alignItems: 'center',
                      }),
                      textAlign: subtitleTextAlign,
                    }}
                  >
                    <span
                      style={{
                        fontSize: `${subtitleFontSize}px` || '12px',
                        fontFamily: subtitleFontFamily || 'Open Sans',
                        fontWeight: subtitleFontWeight || '700',
                        ...(viewMode === 'MOBILE' && {
                          fontSize: `${fontSize}px` || '8.1px',
                          // maxWidth: '225px',
                          display: 'block',
                          marginBottom: '10px',
                        }),
                      }}
                    >
                      {subtitleText}
                    </span>
                  </div>
                </div>
                <div
                  className="button"
                  style={{
                    textAlign: buttonAlignment,
                    width: modalStyle === 'SPLIT' ? '30%' : 'inital',
                    marginTop: '5px',
                  }}
                >
                  <span
                    style={{
                      // marginLeft: viewMode === 'MOBILE' ? '0px' : '10px',
                      display: 'inline-flex',
                      marginRight: viewMode === 'MOBILE' ? '7px' : '0px',
                    }}
                  >
                    {buttonStyle !== 'no_button' && (
                      <Button
                        children={
                          <>
                            {btnText}
                            {buttonStyle === 'arrow' && (
                              <div id="cta">
                                <span
                                  class="arrow primera next bounceAlpha"
                                  style={{
                                    marginLeft: '10px',
                                    backgroundImage: `url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8px' height='8px' viewBox='0 0 792.033 792.033' fill='${encodedTextColor}' style='enable-background:new 0 0 792.033 792.033;' xml:space='preserve'> <g><g id='_x38_'><g><path  d='M617.858,370.896L221.513,9.705c-13.006-12.94-34.099-12.94-47.105,0c-13.006,12.939-13.006,33.934,0,46.874l372.447,339.438L174.441,735.454c-13.006,12.94-13.006,33.935,0,46.874s34.099,12.939,47.104,0l396.346-361.191c6.932-6.898,9.904-16.043,9.441-25.087C627.763,386.972,624.792,377.828,617.858,370.896z'/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>")`,
                                  }}
                                ></span>
                                <span
                                  class="arrow segunda next bounceAlpha"
                                  style={{
                                    backgroundImage: `url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8px' height='8px' viewBox='0 0 792.033 792.033' fill='${encodedTextColor}' style='enable-background:new 0 0 792.033 792.033;' xml:space='preserve'> <g><g id='_x38_'><g><path  d='M617.858,370.896L221.513,9.705c-13.006-12.94-34.099-12.94-47.105,0c-13.006,12.939-13.006,33.934,0,46.874l372.447,339.438L174.441,735.454c-13.006,12.94-13.006,33.935,0,46.874s34.099,12.939,47.104,0l396.346-361.191c6.932-6.898,9.904-16.043,9.441-25.087C627.763,386.972,624.792,377.828,617.858,370.896z'/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>")`,
                                  }}
                                ></span>
                              </div>
                            )}
                          </>
                        }
                        style={{
                          width: 'fit-content',
                          minHeight: '24px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: buttonPadding,
                          borderRadius:
                            borderRadius >= 0 ? `${borderRadius}px` : '3.3px',
                          border:
                            buttonStyle === BUTTON_STYLE[0].value
                              ? `solid 1px ${
                                  buttonColor === 'transparent'
                                    ? 'white'
                                    : buttonColor
                                }`
                              : 'transparent',
                          fontSize: `${fontSize}px` || '9.1px',
                          fontWeight: fontWeight || 'bold',
                          textDecoration:
                            buttonStyle === BUTTON_STYLE[3].value
                              ? `underline`
                              : 'none',
                          backgroundColor:
                            buttonStyle !== BUTTON_STYLE[2].value &&
                            buttonStyle !== BUTTON_STYLE[0].value &&
                            buttonStyle !== 'underlined' &&
                            buttonStyle !== 'arrow'
                              ? buttonColor || 'transparent'
                              : 'transparent',
                          outline: 0,
                          cursor: 'pointer',
                          color: textColor,
                        }}
                        onClick={(e) => {
                          if (openingStyle === OPENING_STYLE[0].value) {
                            window.open(link, '_blank');
                          } else {
                            window.location.replace(link);
                          }
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            isLoading && !isLoadingBanner && (
              <div
                className={classNames('inner-window loading', {
                  'mobile-view': viewMode === 'MOBILE',
                })}
              >
                Loading...
              </div>
            )
          )}
        </div>
      </div>

      {showWelcomePopup && (
        <Popup
          component={() => {
            return (
              <WelcomePopup
                successCB={async () => {
                  setShowWelcomePopup(false);

                  updateUser({
                    variables: {
                      isNew: false,
                    },
                  })
                    .then(() => {})
                    .catch((e) => console.log(e));
                }}
              />
            );
          }}
          closePopup={async (e) => {
            setShowWelcomePopup(false);
            updateUser({
              variables: {
                isNew: false,
              },
            })
              .then(() => {})
              .catch((e) => console.log(e));
          }}
          style={{
            minHeight: '800px',
            minWidth: '986px',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
            padding: 0,
            width: '80%',
          }}
        />
      )}

      {showUpgradePopup && (
        <Popup
          component={() => {
            return (
              <StartIntegrationPopup
                successCB={async () => {
                  setShowUpgradePopup(false);
                }}
                apiKey={apiKey}
              />
            );
          }}
          closePopup={async (e) => {
            setShowUpgradePopup(false);
          }}
          style={{
            minHeight: '840px',
            minWidth: '986px',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
            padding: 0,
            width: '80%',
            overflowY: 'hidden',
          }}
        />
      )}

      {showCampaignsPopup && (
        <Popup
          component={() => {
            return (
              <CampaignPopup
                successCB={async () => {
                  setShowCampaignsPopup(false);
                }}
              />
            );
          }}
          closePopup={async (e) => {
            setShowCampaignsPopup(false);
          }}
          style={{
            minHeight: '800px',
            minWidth: '986px',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
            padding: 0,
            width: '80%',
          }}
        />
      )}

      {showPlanUpgradePopup && (
        <Popup
          component={() => {
            return (
              <Upgrade
                successCB={async () => {
                  setShowPlanUpgradePopup(false);
                  // setShowPopup(true);
                  showSuccessPopup(true);
                }}
                // preStep={userStatus !==   'BASIC' && userStatus !== 'PREMIUM' ? 3 : 1}
                subscriptionCycle={subscriptionCycle}
                targetPlan={
                  userPlan &&
                  userPlan.getUserPlan &&
                  userPlan.getUserPlan.plan &&
                  userPlan.getUserPlan.plan.id
                    ? userPlan.getUserPlan.plan.id < 4 &&
                      userPlan.getUserPlan.plan.id >= 1
                      ? userPlan.getUserPlan.plan.id + 1
                      : userPlan.getUserPlan.plan.id
                    : 2
                }
              />
            );
          }}
          closePopup={async (e) => {
            setShowPlanUpgradePopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '80%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}

      {successPopup && (
        <Popup
          component={() => {
            return (
              <Step3
                successCB={async () => {
                  showSuccessPopup(false);
                  showUpgradePopup(true);
                }}
              />
            );
          }}
          closePopup={async (e) => {
            showSuccessPopup(false);
            showUpgradePopup(true);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}
    </div>
  );
};

export default memo(Customizer);
// export default Customizer;
