import React from 'react';
import styles from './accordion.module.scss';
import classNames from 'classnames';
import ArrowIcon from '../../../ArrowIcon';
import ReactTooltip from 'react-tooltip';

const Accordion = ({
  accordNum,
  title,
  tooltipText,
  activeAccord,
  accordionHandler,
  children,
}) => {
  
  return (
    <div className={styles.accordContainer}>
      <ReactTooltip
        id="tooltip"
        place="right"
        effect="solid"
        arrowColor="transparent"
        className="tooltipCustomizer"
      />
      <div
        className={classNames(
          styles.accordionHeader,
          activeAccord === accordNum && styles.activeAccordion
        )}
        onClick={() => accordionHandler(accordNum)}
        data-for="tooltip"
        data-tip={`${tooltipText}`}
        data-place="right"
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.arrowContainer}>
          <ArrowIcon
            classIcon={classNames(
              styles.arrowIcon,
              activeAccord === accordNum && styles.rotateArrow
            )}
            switcher={activeAccord === accordNum}
          />
        </div>
      </div>

      <div
        className={classNames(
          styles.accordionPanel,
          activeAccord === accordNum && styles.activePanel
        )}
      >
        {children}
      </div>
    </div>
  );
};
export default Accordion;
