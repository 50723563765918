import React from 'react';
import {
  OPENING_STYLE,
  BUTTON_STYLE,
  CLOSE_BTN,
  BUTTON_ALIGNMENT,
} from '../../../layout/constants';
import CustomSelect from './CustomSelect';
import Input from '../../../form/components/Input';
import { ChromePicker } from 'react-color';
import { Slider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  addBtnText,
  addLink,
  addButtonStyle,
  addOpeningStyle,
  addButtonAlignment,
  addButtonPadding,
  addButtonColor,
  addTextColor,
  addBorderRadius,
  addCloseButton,
} from '../../../redux/customizerSidebarSlice';

const ButtonComponent = () => {
 
  const dispatch = useDispatch();
  const {
    btnText,
    link,
    buttonStyle,
    openingStyle,
    style,
    buttonAlignment,
    buttonPadding,
    buttonColor,
    textColor,
    borderRadius,
    closeButton,
  } = useSelector((state) => state.customizer.customizerData); 

  let mappedOpeningStyle = OPENING_STYLE.filter(
    (f) => f.value === openingStyle
  );
  let mappedButtonAlignment = BUTTON_ALIGNMENT.filter(
    (f) => f.value === buttonAlignment
  );
  let mappedButtonStyle = BUTTON_STYLE.filter((f) => f.value === buttonStyle);
  let mappedCloseButton = CLOSE_BTN.filter((f) => f.value === closeButton);

  return (
    <>
      <div className="customizer-menu-free">
        <div className="f-item">
          <div className="tit">Copy</div>
          <Input
            value={btnText}
            onChange={(e) => {
              dispatch(addBtnText(e.target.value));
            }}
            style={{
              width: '100%',
              height: '57px',
            }}
          />
        </div>

        <div className="f-item">
          <div className="tit">Link</div>
          <Input
            value={link}
            onChange={(e) => {
              dispatch(addLink(e.target.value));
            }}
            style={{
              width: '100%',
              height: '57px',
            }}
          />
        </div>

        <CustomSelect
          label="Button Style"
          activeMenuItem={mappedButtonStyle[0]}
          menuItems={BUTTON_STYLE}
          actionFunc={addButtonStyle}
        />

        <CustomSelect
          label="Opening Style"
          activeMenuItem={mappedOpeningStyle[0]}
          menuItems={OPENING_STYLE}
          actionFunc={addOpeningStyle}
        />

        {style === 'BOTTOM_BANNER' ? (
          <div>
            <CustomSelect
              label="Alignement"
              activeMenuItem={mappedButtonAlignment[0]}
              menuItems={BUTTON_ALIGNMENT}
              actionFunc={addButtonAlignment}
            />

            <div className="f-item">
              <div className="tit">Padding</div>
              <Input
                value={buttonPadding}
                onChange={(e) => {
                  dispatch(addButtonPadding(e.target.value));
                }}
                style={{
                  width: '100%',
                  height: '57px',
                }}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="f-item">
          <div className="tit">Button Color</div>
          <ChromePicker
            color={buttonColor}
            onChange={(e) => {
              dispatch(addButtonColor(e.hex));
            }}
          />
        </div>

        <div className="f-item">
          <div className="tit">Text Color</div>
          <ChromePicker
            color={textColor}
            onChange={(e) => {
              dispatch(addTextColor(e.hex));
            }}
          />
        </div>

        <div className="f-item">
          <div className="tit">Border Radius</div>
          <Slider
            defaultValue={borderRadius}
            disabled={
              buttonStyle !== BUTTON_STYLE[0].value &&
              buttonStyle !== BUTTON_STYLE[1].value
            }
            onChange={(value) => {
              dispatch(addBorderRadius(value));
            }}
          />
        </div>

        <CustomSelect
          label="Close Button"
          activeMenuItem={mappedCloseButton[0]}
          menuItems={CLOSE_BTN}
          actionFunc={addCloseButton}
        />
      </div>
    </>
  );
};
export default ButtonComponent;
