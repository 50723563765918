import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useLocation, useHistory } from 'react-router-dom';
import CustomizerSidebar from '../components/SidebarComponents/CustomizerSidebarSecondVer';
import LoadingBar from 'react-top-loading-bar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserSubscriptionPlanData } from '../redux/userSlice';
import Navbar from '../components/SidebarComponents/Navbar';
import { useGoogleLogout } from 'react-google-login';


const { Sider } = Layout;

const HeaderLogo = () => {
  let history = useHistory();
  return (
    <div
      className="header-logo pointer"
      onClick={() => {
        if (!window.location.pathname.includes('/onboarding')) {
          history.push('/');
        }
      }}
    >
      <Logo className="onboarding-header" fill="#0a2540" />
    </div>
  );
};

const Sidebar = () => {
  const clientId =
    '627603514642-2nu5og7kb64relqi1nan8hcfd490rp3r.apps.googleusercontent.com';

  const [progress, setProgress] = useState(70);
  
  const location = useLocation().pathname;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserSubscriptionPlanData());
  }, []);

  // dispatch(fetchMeData());
  // const meData = useSelector((state) => state.user.me);
  const loadingPlan = useSelector((state) => state.user.loadingPlan);
  const userSubPlan = useSelector((state) => state.user.getUserPlan);

  const { signOut, loaded } = useGoogleLogout({
    clientId,
    onFailure: (t) => console.log("error logout ", t),
    onLogoutSuccess: (t) => console.log("success logout", t)
  });  

  if (loadingPlan) {
    return (
      <LoadingBar
        color="#a172ff"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    );
  }

  // const {
  //   me: { isNew, skippedOnboarding, pages },
  // } = data;

  // const subscriptionCycle =
  //   userPlan && userPlan.getUserPlan
  //     ? userPlan.getUserPlan.subscriptionCycle
  //     : '';

  return (
    <Sider
      className="sidebar-wrapper"
      width={location === '/customizer' ? '323px' : '205px'}
      style={location === '/' && { overflowY: 'visible' }}
    >
      <HeaderLogo />
      {location === '/customizer' ? (
        <CustomizerSidebar
        // pages={pages}
        />
      ) : (

        <Navbar signOut={signOut} />
        // <div className="menu-wrapper">
        //   <ReactTooltip
        //     id="tooltipProject"
        //     place="right"
        //     effect="solid"
        //     arrowColor="transparent"
        //     className="tooltipSidebar"
        //   />
        //   <div
        //     className={classNames('menu-item', 'tooltip', {
        //       activeMenu: activeMenu === 'Projects',
        //     })}
        //     onClick={(e) => {
        //       setActiveMenu('Projects');
        //       browserHistory.push('/');
        //     }}
        //     data-for="tooltipProject"
        //     data-tip="All pages and campaigns"
        //     data-place="right"
        //   >
        //     <Link
        //       to="#"
        //       title="Projects"
        //       className={classNames({
        //         activeLink: activeMenu === 'Projects',
        //       })}
        //     >
        //       Projects
        //     </Link>
        //   </div>

        //   <ReactTooltip
        //     id="tooltipCustomizer"
        //     place="right"
        //     effect="solid"
        //     arrowColor="transparent"
        //     className="tooltipSidebar"
        //   />
        //   <div
        //     className="menu-item"
        //     onClick={(e) => {
        //       setActiveMenu('Projects');
        //       e.preventDefault();
        //       updateCustomizerClient({
        //         variables: { ...customizerClient.customizer, isOpen: true },
        //       });
        //       browserHistory.push('/customizer');
        //     }}
        //     data-for="tooltipCustomizer"
        //     data-tip="Customize your banner"
        //     data-place="right"
        //   >
        //     <Link to="#" title="Customizer">
        //       Customizer
        //     </Link>
        //   </div>

        //   <div
        //     className="bottom-items"
        //     style={{ bottom: bannerVisible ? '80px' : '30px' }}
        //   >
        //     {userPlan &&
        //       userPlan.getUserPlan &&
        //       userPlan.getUserPlan.plan &&
        //       userPlan.getUserPlan.plan.id < 4 && (
        //         <div
        //           className={classNames('menu-item custom-menu-item')}
        //           onClick={(e) => {
        //             setShowUpgradePopup(true);
        //           }}
        //         >
        //           <Link to="#" title="Upgrade Now" className="">
        //             Upgrade Now
        //             <RightArrow
        //               style={{
        //                 width: '9px',
        //                 height: '9px',
        //                 fill: '#0052ff',
        //               }}
        //             />
        //           </Link>
        //         </div>
        //       )}

        //     <div
        //       className={classNames('menu-item', {
        //         activeMenu: activeMenu === 'Settings',
        //       })}
        //       onClick={(e) => {
        //         setActiveMenu('Settings');
        //         browserHistory.push('/settings');
        //       }}
        //     >
        //       <Link
        //         to="#"
        //         title="Settings"
        //         className={classNames({
        //           activeLink: activeMenu === 'Settings',
        //         })}
        //       >
        //         Settings
        //       </Link>
        //     </div>
        //     <div
        //       className="menu-item"
        //       onClick={(e) => {
        //         e.preventDefault();
        //         auth.removeAccessToken();
        //         localStorage.setItem('viewmode', null);
        //         window.location.reload();
        //       }}
        //     >
        //       <Link to="#" title="Log out">
        //         Log out
        //       </Link>
        //     </div>
        //   </div>
        // </div>
      )}

      {/* {showUpgradePopup && (
        <Popup
          component={() => {
            return (
              <Upgrade
                successCB={async () => {
                  setShowUpgradePopup(false);
                  showSuccessPopup(true);
                }}
                subscriptionCycle={subscriptionCycle}
                targetPlan={
                  userPlan &&
                  userPlan.getUserPlan &&
                  userPlan.getUserPlan.plan &&
                  userPlan.getUserPlan.plan.id
                    ? userPlan.getUserPlan.plan.id < 4 &&
                      userPlan.getUserPlan.plan.id >= 1
                      ? userPlan.getUserPlan.plan.id + 1
                      : userPlan.getUserPlan.plan.id
                    : 2
                }
              />
            );
          }}
          closePopup={async (e) => {
            setShowUpgradePopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}

      {successPopup && (
        <Popup
          component={() => {
            return (
              <Step3
                successCB={async () => {
                  showSuccessPopup(false);
                }}
              />
            );
          }}
          closePopup={async (e) => {
            showSuccessPopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )} */}
    </Sider>
  );
};

export default Sidebar;
