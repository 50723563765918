import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { apolloClient } from './apollo/apolloClient';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import './assets/styles/main.scss';

import App from './App';
import { browserHistory } from './browserHistory';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ApolloHooksProvider client={apolloClient}>
      <Provider store={store}>
        <Router history={browserHistory}>
          <App />
        </Router>
      </Provider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
