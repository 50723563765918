import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { auth } from '../../signupLogin/auth';
import Popup from '../../components/Popup';
import Upgrade, { Step3 } from '../../upgrade/components/Upgrade';
import RightArrow from './RightArrow';

const Navbar = ({ signOut }) => {
  const [successPopup, showSuccessPopup] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  const userPlanData = useSelector((state) => state.user.getUserPlan);

  return (
    <React.Fragment>
      <div className="menu-wrapper">
        <ReactTooltip
          id="tooltipProject"
          place="right"
          effect="solid"
          arrowColor="transparent"
          className="tooltipSidebar"
        />
        <div
          className="menu-item tooltip"
          data-for="tooltipProject"
          data-tip="All pages and campaigns"
          data-place="right"
        >
          <Link to="/" title="Projects">
            Projects
          </Link>
        </div>

        <ReactTooltip
          id="tooltipCustomizer"
          place="right"
          effect="solid"
          arrowColor="transparent"
          className="tooltipSidebar"
        />
        <div
          className="menu-item"
          data-for="tooltipCustomizer"
          data-tip="Customize your banner"
          data-place="right"
        >
          <Link to="/customizer" title="Customizer">
            Customizer
          </Link>
        </div>

        <div className="bottom-items" style={{ bottom: '30px' }}>
          {userPlanData && userPlanData.plan && userPlanData.plan.id < 4 && (
            <div className="menu-item custom-menu-item">
              <button onClick={() => setShowUpgradePopup(true)}>
                Upgrade Now
                <RightArrow />
              </button>
            </div>
          )}

          <div
            className="menu-item"
            // className={classNames('menu-item', {
            //   activeMenu: activeMenu === 'Settings',
            // })}
            // onClick={(e) => {
            //   // setActiveMenu('Settings');
            //   browserHistory.push('/settings');
            // }}
          >
            <Link to="/settings" title="Settings">
              Settings
            </Link>
          </div>
          <div
            className="menu-item"
            onClick={(e) => {
              e.preventDefault();
              auth.removeAccessToken();
              localStorage.setItem('viewmode', null);
              signOut();

              window.location.reload();
            }}
          >
            <span>Log out</span>
          </div>
        </div>
      </div>

      {showUpgradePopup && (
        <Popup
          component={() => {
            return (
              <Upgrade
                successCB={async () => {
                  setShowUpgradePopup(false);
                  showSuccessPopup(true);
                }}
                subscriptionCycle={userPlanData.subscriptionCycle}
                targetPlan={
                  userPlanData && userPlanData.plan && userPlanData.plan.id
                    ? userPlanData.plan.id < 4 && userPlanData.plan.id >= 1
                      ? userPlanData.plan.id + 1
                      : userPlanData.plan.id
                    : 2
                }
              />
            );
          }}
          closePopup={async (e) => {
            setShowUpgradePopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}

      {successPopup && (
        <Popup
          component={() => {
            return (
              <Step3
                successCB={async () => {
                  showSuccessPopup(false);
                }}
              />
            );
          }}
          closePopup={async (e) => {
            showSuccessPopup(false);
          }}
          style={{
            minHeight: '800px',
            width: '60%',
            marginLeft: '0px',
            marginRight: '0px',
            margin: '0 auto',
            zIndex: 1000000,
          }}
        />
      )}
    </React.Fragment>
  );
};
export default Navbar;
