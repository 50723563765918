import { createSlice } from '@reduxjs/toolkit';
// import { apolloClient } from '../apollo/apolloClient';
// import { USER_CAMPAIGNS } from '../bannernote/customizer/gql';

const initialState = {
  isLoading: true,
  isLoadingBanner: true,
  activeCampaign: null,
  customizerData: {
    animationDelay: 'NO_DELAY',
    animationEffect: 'NONE',
    animationStyle: 'NONE',
    animationType: '',
    attentionCadence: 'NONE',
    attentionSeeker: 'NONE',
    backgroundColor: '',
    bannerText: '',
    borderRadius: 0,
    branding: 'WITH_BRANDING',
    btnText: 'READ UPDATES',
    buttonAlignment: 'left',
    buttonColor: 'transparent',
    buttonPadding: '0 12px',
    buttonStyle: 'framed',
    cadenceInterval: 0,
    closeButton: 'show',
    customPositionDiv: '',
    fontColor: '',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: '700',
    link: 'https://google.com',
    modalAlignment: 'LEFT',
    modalBorderRadius: 15,
    modalImage: '',
    modalShadow: '',
    modalStyle: 'SIMPLE',
    openingStyle: '_blank',
    position: '',
    size: 'SMALL',
    style: 'TOP_BANNER',
    subtitleFontFamily: 'Open Sans',
    subtitleFontSize: 12,
    subtitleFontWeight: '700',
    subtitleText: '',
    subtitleTextAlign: 'LEFT',
    textColor: '',
    titleTextAlign: 'LEFT',
  },
  userCampaigns: [],
  isActiveBtn: false,
};

// export const fetchUserCampaignData = createAsyncThunk(
//   'customizer/fetchUserCampaign',
//   async () => {
//     try {
//       const userCampaignData = await apolloClient.query({
//         query: USER_CAMPAIGNS,
//     fetchPolicy: 'network-only';
//       });
//       // console.log('userCampaignData from Fetch: ', userCampaignData);
//       return userCampaignData;
//     } catch (error) {
//       console.log('error: ', error);
//     }

//     const userCampaignData = useQuery(USER_CAMPAIGNS, {
//       fetchPolicy: 'network-only',
//     });
//   }
// );

export const customizerSidebarSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    fetchUserCampaign: (state, action) => {
      const getUserCampaigns = action.payload.getUserCampaigns;
      state.userCampaigns = getUserCampaigns;
      const activeCampaign = getUserCampaigns.filter((item) => {
        return item.id === state.activeCampaign;
      });

      if (activeCampaign.length) {
        const { id, ...activeCampCustomizer } = activeCampaign[0].customizer;
        state.customizerData = activeCampCustomizer;
      } else {
        const { id, ...customizerObj } = getUserCampaigns[0].customizer;
        state.customizerData = customizerObj;
        state.activeCampaign = id;
      }
      state.isLoading = false;
    },
    addStyleBanner: (state, action) => {
      state.customizerData.animationEffect = 'NONE';
      state.customizerData.style = action.payload;
      state.isActiveBtn = true;
    },
    addBannerText: (state, action) => {
      state.customizerData.bannerText = action.payload;
      state.isActiveBtn = true;
    },
    addFontFamily: (state, action) => {
      state.customizerData.fontFamily = action.payload;
      state.isActiveBtn = true;
    },
    addFontWeight: (state, action) => {
      state.customizerData.fontWeight = action.payload;
      state.isActiveBtn = true;
    },
    addFontSize: (state, action) => {
      state.customizerData.fontSize = action.payload;
      state.isActiveBtn = true;
    },
    addTitleTextAlign: (state, action) => {
      state.customizerData.titleTextAlign = action.payload;
      state.isActiveBtn = true;
    },
    addSubtitleText: (state, action) => {
      state.customizerData.subtitleText = action.payload;
      state.isActiveBtn = true;
    },
    addSubtitleFontFamily: (state, action) => {
      state.customizerData.subtitleFontFamily = action.payload;
      state.isActiveBtn = true;
    },
    addSubtitleFontWeight: (state, action) => {
      state.customizerData.subtitleFontWeight = action.payload;
      state.isActiveBtn = true;
    },
    addSubtitleFontSize: (state, action) => {
      state.customizerData.subtitleFontSize = action.payload;
      state.isActiveBtn = true;
    },
    addSubtitleTextAlign: (state, action) => {
      state.customizerData.subtitleTextAlign = action.payload;
      state.isActiveBtn = true;
    },
    addBtnText: (state, action) => {
      state.customizerData.btnText = action.payload;
      state.isActiveBtn = true;
    },
    addLink: (state, action) => {
      state.customizerData.link = action.payload;
      state.isActiveBtn = true;
    },
    addButtonStyle: (state, action) => {
      state.customizerData.buttonStyle = action.payload;
      state.isActiveBtn = true;
    },
    addOpeningStyle: (state, action) => {
      state.customizerData.openingStyle = action.payload;
      state.isActiveBtn = true;
    },
    addButtonAlignment: (state, action) => {
      state.customizerData.buttonAlignment = action.payload;
      state.isActiveBtn = true;
    },
    addButtonPadding: (state, action) => {
      state.customizerData.buttonPadding = action.payload;
      state.isActiveBtn = true;
    },
    addButtonColor: (state, action) => {
      state.customizerData.buttonColor = action.payload;
      state.isActiveBtn = true;
    },
    addTextColor: (state, action) => {
      state.customizerData.textColor = action.payload;
      state.isActiveBtn = true;
    },
    addBorderRadius: (state, action) => {
      state.customizerData.borderRadius = action.payload;
      state.isActiveBtn = true;
    },
    addCloseButton: (state, action) => {
      state.customizerData.closeButton = action.payload;
      state.isActiveBtn = true;
    },
    addBackgroundColor: (state, action) => {
      state.customizerData.backgroundColor = action.payload;
      state.isActiveBtn = true;
    },
    addFontColor: (state, action) => {
      state.customizerData.fontColor = action.payload;
      state.isActiveBtn = true;
    },
    addBranding: (state, action) => {
      state.customizerData.branding = action.payload;
      state.isActiveBtn = true;
    },
    addSize: (state, action) => {
      state.customizerData.size = action.payload;
      state.isActiveBtn = true;
    },
    addPosition: (state, action) => {
      state.customizerData.position = action.payload;
      state.isActiveBtn = true;
    },
    addCustomPositionDiv: (state, action) => {
      state.customizerData.customPositionDiv = action.payload;
      state.isActiveBtn = true;
    },
    addAnimationStyle: (state, action) => {
      state.customizerData.animationStyle = action.payload;
      state.customizerData.animationType = 'NONE';
      state.isActiveBtn = true;
    },
    addAnimationType: (state, action) => {
      state.customizerData.animationType = action.payload;
      state.isActiveBtn = true;
    },
    addAnimationDelay: (state, action) => {
      state.customizerData.animationDelay = action.payload;
      state.isActiveBtn = true;
    },
    addAttentionSeeker: (state, action) => {
      if (action.payload === 'NONE') {
        state.customizerData.attentionSeeker = action.payload;
        state.customizerData.attentionCadence = 'NONE';
        state.customizerData.cadenceInterval = 0;
        state.isActiveBtn = true;
      } else {
        state.customizerData.attentionSeeker = action.payload;
        state.isActiveBtn = true;
      }
    },
    addAttentionCadence: (state, action) => {
      if (action.payload === 'NONE') {
        state.customizerData.attentionCadence = action.payload;
        state.customizerData.cadenceInterval = 0;
        state.isActiveBtn = true;
      } else {
        state.customizerData.attentionCadence = action.payload;
        state.customizerData.cadenceInterval = 1;
        state.isActiveBtn = true;
      }
    },
    addCadenceInterval: (state, action) => {
      state.customizerData.cadenceInterval = action.payload;
      state.isActiveBtn = true;
    },
    addAnimationEffect: (state, action) => {
      state.customizerData.animationEffect = action.payload;
      state.isActiveBtn = true;
    },
    addModalStyle: (state, action) => {
      state.customizerData.modalStyle = action.payload;
      state.isActiveBtn = true;
      if (action.payload !== 'WITH_IMAGE') {
        state.customizerData.modalImage = '';
      }
    },
    addModalAlignment: (state, action) => {
      state.customizerData.modalAlignment = action.payload;
      state.isActiveBtn = true;
    },
    addModalBorderRadius: (state, action) => {
      state.customizerData.modalBorderRadius = action.payload;
      state.isActiveBtn = true;
    },
    addModalShadow: (state, action) => {
      state.customizerData.modalShadow = action.payload;
      state.isActiveBtn = true;
    },
    addModalImage: (state, action) => {
      state.customizerData.modalImage = action.payload;
      state.isActiveBtn = true;
    },
    changeCampaign: (state, action) => {
      const { id, ...selelectedCampaignCustomizer } = action.payload.customizer;
      state.activeCampaign = id;
      state.customizerData = selelectedCampaignCustomizer;
      state.isActiveBtn = false;
    },
    hidePublishButton: (state, action) => {
      state.isActiveBtn = false;
    },
    setLoadingCustomizer: (state, action) => {
      state.isLoadingBanner = true;
      // state.isLoading = true;
    },
    hideLoadingCustomizer: (state, action) => {
      state.isLoadingBanner = false;
      // state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchUserCampaignData.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchUserCampaignData.fulfilled, (state, action) => {
    //   const getUserCampaigns = action.payload.data.getUserCampaigns;
    //   state.userCampaigns = getUserCampaigns;
    //   console.log('getUserCampaigns: ', getUserCampaigns);
    //   const activeCampaign = getUserCampaigns.filter((item) => {
    //     return item.id === state.activeCampaign;
    //   });
    //   if (activeCampaign.length) {
    //     const { id, ...activeCampCustomizer } = activeCampaign[0].customizer;
    //     state.customizerData = activeCampCustomizer;
    //   } else {
    //     const { id, ...customizerObj } = getUserCampaigns[0].customizer;
    //     state.customizerData = customizerObj;
    //     state.activeCampaign = id;
    //   }
    //   state.isLoading = false;
    // });
  },
});

export const {
  fetchUserCampaign,
  addStyleBanner,
  addBannerText,
  addFontFamily,
  addFontWeight,
  addFontSize,
  addTitleTextAlign,
  addSubtitleText,
  addSubtitleFontWeight,
  addSubtitleFontFamily,
  addSubtitleFontSize,
  addSubtitleTextAlign,
  addBtnText,
  addLink,
  addButtonStyle,
  addOpeningStyle,
  addButtonAlignment,
  addButtonPadding,
  addButtonColor,
  addTextColor,
  addBorderRadius,
  addCloseButton,
  addBackgroundColor,
  addFontColor,
  addBranding,
  addSize,
  addPosition,
  addCustomPositionDiv,
  addAnimationStyle,
  addAnimationType,
  addAnimationDelay,
  addAttentionSeeker,
  addAttentionCadence,
  addCadenceInterval,
  addAnimationEffect,
  addModalStyle,
  addModalAlignment,
  addModalBorderRadius,
  addModalShadow,
  addModalImage,
  changeCampaign,
  hidePublishButton,
  setLoadingCustomizer,
  hideLoadingCustomizer,
} = customizerSidebarSlice.actions;

export default customizerSidebarSlice.reducer;
