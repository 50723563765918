import gql from "graphql-tag";

export const CUSTOMIZER_QUERY = gql`
  query customizerQuery {
    customizer @client {
      isOpen
      branding

      style
      bannerText
      fontFamily
      fontWeight

      titleTextAlign
      subtitleText
      subtitleFontFamily
      subtitleFontSize
      subtitleFontWeight
      subtitleTextAlign

      btnText
      link
      openingStyle
      buttonStyle
      buttonColor
      textColor
      closeButton
      buttonAlignment
      buttonPadding

      backgroundColor
      fontColor

      activeCampaign
      size
      borderRadius
      position
      customPositionDiv
      animationStyle
      animationDelay
      animationEffect
      animationType
      attentionSeeker
      attentionCadence
      cadenceInterval
      fontSize
      modalStyle
      modalAlignment
      modalBorderRadius
      modalShadow
      modalImage
      isLoading
    }
  }
`;

export const CUSTOMIZER_QUERY_SERVER = gql`
  query getUserCustomizer {
    getUserCustomizer {
      branding
      style
      bannerText
      fontFamily
      fontWeight
      titleTextAlign
      subtitleText
      subtitleFontFamily
      subtitleFontSize
      subtitleFontWeight
      subtitleTextAlign
      btnText
      link
      openingStyle
      buttonStyle
      buttonColor
      textColor
      closeButton
      buttonAlignment
      buttonPadding
      backgroundColor
      fontColor
      size
      borderRadius
      position
      customPositionDiv
      animationStyle
      animationDelay
      animationEffect
      animationType
      attentionCadence
      cadenceInterval
      modalStyle
      fontSize
      modalAlignment
      modalBorderRadius
      modalShadow
      modalImage
    }
  }
`;

export const USER_CAMPAIGNS = gql`
  query getUserCampaigns {
    getUserCampaigns {
      id
      name
      userId
      analytics {
        clicks
        impressions
        campaignId
        id
        updatedAt
      }
      customizer {
        id
        branding
        style
        bannerText
        fontFamily
        fontWeight
        titleTextAlign
        subtitleText
        subtitleFontFamily
        subtitleFontSize
        subtitleFontWeight
        subtitleTextAlign
        btnText
        link
        openingStyle
        buttonStyle
        buttonColor
        textColor
        closeButton
        buttonAlignment
        buttonPadding
        backgroundColor
        fontColor
        size
        borderRadius
        position
        customPositionDiv
        animationStyle
        animationDelay
        animationEffect
        animationType
        attentionSeeker
        attentionCadence
        cadenceInterval
        fontSize
        modalStyle
        modalAlignment
        modalBorderRadius
        modalShadow
        modalImage
      }
    }
  }
`;

//updateCampaignCustomizer
export const CAMPAIGN_CUSTOMIZER_MUTATION = gql`
  mutation updateCampaignCustomizer(
    $branding: String
    $style: String
    $bannerText: String
    $fontFamily: String
    $fontWeight: String
    $titleTextAlign: String
    $subtitleText: String
    $subtitleFontFamily: String
    $subtitleFontWeight: String
    $subtitleFontSize: Int
    $subtitleTextAlign: String
    $btnText: String
    $link: String
    $openingStyle: String
    $buttonStyle: String
    $buttonColor: String
    $textColor: String
    $closeButton: String
    $buttonAlignment: String
    $buttonPadding: String
    $backgroundColor: String
    $fontColor: String
    $campaignId: Int
    $size: String
    $borderRadius: Int
    $position: String
    $customPositionDiv: String
    $animationStyle: String
    $animationDelay: String
    $animationEffect: String
    $animationType: String
    $attentionSeeker: String
    $attentionCadence: String
    $cadenceInterval: Int
    $fontSize: Int
    $modalStyle: String
    $modalAlignment: String
    $modalBorderRadius: Int
    $modalShadow: String
    $modalImage: String
  ) {
    updateCampaignCustomizer(
      branding: $branding
      style: $style
      fontFamily: $fontFamily
      fontWeight: $fontWeight
      bannerText: $bannerText
      titleTextAlign: $titleTextAlign
      subtitleText: $subtitleText
      subtitleFontFamily: $subtitleFontFamily
      subtitleFontWeight: $subtitleFontWeight
      subtitleFontSize: $subtitleFontSize
      subtitleTextAlign: $subtitleTextAlign

      btnText: $btnText
      link: $link
      openingStyle: $openingStyle
      buttonStyle: $buttonStyle
      buttonColor: $buttonColor
      textColor: $textColor
      closeButton: $closeButton
      buttonAlignment: $buttonAlignment
      buttonPadding: $buttonPadding

      backgroundColor: $backgroundColor
      fontColor: $fontColor

      campaignId: $campaignId
      size: $size
      borderRadius: $borderRadius
      position: $position
      customPositionDiv: $customPositionDiv

      animationStyle: $animationStyle
      animationDelay: $animationDelay
      animationEffect: $animationEffect
      animationType: $animationType
      attentionSeeker: $attentionSeeker
      attentionCadence: $attentionCadence
      cadenceInterval: $cadenceInterval
      fontSize: $fontSize
      modalStyle: $modalStyle
      modalAlignment: $modalAlignment
      modalBorderRadius: $modalBorderRadius
      modalShadow: $modalShadow
      modalImage: $modalImage
    )
  }
`;

export const CUSTOMIZER_MUTATION = gql`
  mutation setCustomizerData(
    $isOpen: Boolean
    $branding: String
    $style: String
    $bannerText: String
    $fontFamily: String
    $fontWeight: String
    $btnText: String
    $titleTextAlign: String
    $subtitleText: String
    $subtitleFontFamily: String
    $subtitleFontWeight: String
    $subtitleFontSize: Int
    $subtitleTextAlign: String
    $link: String
    $openingStyle: String
    $buttonStyle: String
    $buttonColor: String
    $textColor: String
    $closeButton: String
    $buttonAlignment: String
    $buttonPadding: String
    $backgroundColor: String
    $fontColor: String
    $activeCampaign: Int
    $size: String
    $borderRadius: Int
    $position: String
    $customPositionDiv: String
    $animationStyle: String
    $animationDelay: String
    $animationEffect: String
    $animationType: String
    $attentionSeeker: String
    $attentionCadence: String
    $cadenceInterval: Int
    $fontSize: Int
    $modalStyle: String
    $isLoading: Boolean
    $modalAlignment: String
    $modalBorderRadius: Int
    $modalShadow: String
    $modalImage: String
  ) {
    setCustomizerData(
      isOpen: $isOpen
      branding: $branding

      style: $style
      fontFamily: $fontFamily
      fontWeight: $fontWeight
      bannerText: $bannerText
      titleTextAlign: $titleTextAlign
      subtitleText: $subtitleText
      subtitleFontFamily: $subtitleFontFamily
      subtitleFontWeight: $subtitleFontWeight
      subtitleFontSize: $subtitleFontSize
      subtitleTextAlign: $subtitleTextAlign

      btnText: $btnText
      link: $link
      openingStyle: $openingStyle
      buttonStyle: $buttonStyle
      buttonColor: $buttonColor
      textColor: $textColor
      closeButton: $closeButton
      buttonAlignment: $buttonAlignment
      buttonPadding: $buttonPadding

      backgroundColor: $backgroundColor
      fontColor: $fontColor

      activeCampaign: $activeCampaign
      size: $size
      borderRadius: $borderRadius
      position: $position
      customPositionDiv: $customPositionDiv
      animationStyle: $animationStyle
      animationDelay: $animationDelay
      animationEffect: $animationEffect
      animationType: $animationType
      attentionSeeker: $attentionSeeker
      attentionCadence: $attentionCadence
      cadenceInterval: $cadenceInterval
      fontSize: $fontSize
      modalStyle: $modalStyle
      isLoading: $isLoading
      modalAlignment: $modalAlignment
      modalBorderRadius: $modalBorderRadius
      modalShadow: $modalShadow
      modalImage: $modalImage
    ) @client {
      isOpen
      branding

      style
      fontFamily
      fontWeight
      bannerText
      titleTextAlign
      subtitleText
      subtitleFontFamily
      subtitleFontWeight
      subtitleFontSize
      subtitleTextAlign

      backgroundColor
      fontColor

      buttonAlignment
      buttonPadding

      activeCampaign
      size
      borderRadius
      position
      customPositionDiv
      animationStyle
      animationDelay
      animationEffect
      animationType
      attentionSeeker
      attentionCadence
      cadenceInterval
      fontSize
      modalStyle
      isLoading
      modalAlignment
      modalBorderRadius
      modalShadow
      modalImage
    }
  }
`;

export const CUSTOMIZER_ACTIVE_CAMPAIGN = gql `
    mutation updateActiveCampaign( $activeCampaign: Int! ) {
        updateActiveCampaign(
            activeCampaign: $activeCampaign
        )
    }
`;

export const CUSTOMIZER_MUTATION_SERVER = gql`
  mutation updateCustomizer(
    $position: String
    $text: String
    $appearance: String
    $customDivId: String
    $customDivDirection: String
  ) {
    updateCustomizer(
      position: $position
      text: $text
      appearance: $appearance
      customDivId: $customDivId
      customDivDirection: $customDivDirection
    ) {
      position
      text
      appearance
      customDivId
      customDivDirection
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($name: String!) {
    createCampaign(name: $name) {
      id
      name
      customizer {
        branding
        style
        bannerText
        fontFamily
        fontWeight
        btnText
        link
        openingStyle
        buttonStyle
        buttonColor
        textColor
        closeButton
        backgroundColor
        fontColor
        size
        borderRadius
        fontSize
      }
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image)
  }
`;
