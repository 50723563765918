import React, { useState, useEffect, useRef } from 'react';
import styles from './upload_img.module.scss';
import { useDispatch } from 'react-redux';
import { addModalImage } from '../../../../redux/customizerSidebarSlice';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

const UploadImageButton = () => {
  const dispatch = useDispatch();
  const [imgFile, setImgFile] = useState();
 
  const fileInputRef = useRef();
 
  const addImageHandler = (event) => {
    const file = event.target.files[0];
    if (file && file.type.slice(0, 5) === 'image') {
      setImgFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(addModalImage(reader.result));
      };
      reader.readAsDataURL(file);
    } else {
      dispatch(addModalImage(''));
      setImgFile('');
    }
  };

  const removeImageHandler = () => {
    setImgFile('');
    dispatch(addModalImage(''));
  };

  return (
    <React.Fragment>
      <div className={styles.buttonContainer}>
        <form>
          <button
            className={styles.buttonUpload}
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current.click();
            }}
          >
            Add Image
          </button>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept="image/*"
            onChange={(event) => addImageHandler(event)}
          />
        </form>
      </div>

      {imgFile && (
        <div className={styles.fileContainer}>
          <p className={styles.fileName}>
            <PaperClipOutlined />
            <span>{imgFile.name}</span>
          </p>
          <button className={styles.removeBtn} onClick={removeImageHandler}>
            <DeleteOutlined />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};
export default UploadImageButton;
