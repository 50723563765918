import { useQuery } from '@apollo/react-hooks';
import { CUSTOMIZER_QUERY, CUSTOMIZER_QUERY_SERVER, USER_CAMPAIGNS } from './gql';

export const useCustomizerQueryClient = () => useQuery(CUSTOMIZER_QUERY, {
});
export const useCustomizerQueryServer = () =>
    useQuery(CUSTOMIZER_QUERY_SERVER, {
        fetchPolicy: 'network-only',
    });

export const useUserCampaignsServer = () =>
    useQuery(USER_CAMPAIGNS, {
        fetchPolicy: 'network-only',
    });
