import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useQuery } from '@apollo/react-hooks';
import { ContactsOutlined } from '@ant-design/icons';
import { ME_QUERY, ME_QUERY_CLIENT } from '../rootGql';
import { USER_SUBSCRIPTION_PLAN } from '../user/gql';
import { apolloClient } from '../apollo/apolloClient';

const initialState = {
  loadingPlan: true,
  loadingMeData: true,
  getUserPlan: {
    isInTrialPeriod: true,
    plan: {
      id: null,
      impressions: 0,
      monthlyPriceAmount: 0,
      monthlyPriceId: '',
      pages: 0,
      type: '',
      yearlyPriceAmount: null,
      yearlyPriceId: '',
    },
    planId: null,
    status: '',
    stripeCustomerId: null,
    subscription: null,
    subscriptionCycle: '',
    trialEnds: null,
  },
  me: {
    activeCampaign: 0,
    apiKey: '',
    email: '',
    fullName: '',
    id: null,
    isNew: false,
    pages: [],
    reachedLimit: false,
    role: '',
    subscription: null,
    subscriptionCycle: '',
  },
};

export const fetchMeData = createAsyncThunk('user/meData', async () => {
  const meData = await apolloClient.query({
    query: ME_QUERY,
  });
  return meData;
});
export const fetchUserSubscriptionPlanData = createAsyncThunk(
  'user/userSubscriptionPlan',
  async () => {
    const userPlanData = await apolloClient.query({
      query: USER_SUBSCRIPTION_PLAN,
    });
    return userPlanData;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMeData.pending, (state, action) => {
      state.loadingMeData = true;
    });
    builder.addCase(fetchMeData.fulfilled, (state, action) => {
      // console.log('Me data: ', action.payload);
      state.me = action.payload.data.me;
      state.loadingMeData = false;
    });
    builder.addCase(fetchUserSubscriptionPlanData.pending, (state, action) => {
      state.loadingPlan = true;
    });
    builder.addCase(
      fetchUserSubscriptionPlanData.fulfilled,
      (state, action) => {
        // console.log('userPlanData', action.payload.data);
        state.getUserPlan = action.payload.data.getUserPlan;
        state.loadingPlan = false;
      }
    );
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
